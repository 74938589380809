.row-btn {
  display: flex;
  justify-content: center;

  margin-top: 45px;
  margin-bottom: 52px;
  &__link {
    display: block;

    margin: 0 15px;
    padding: 14px 57px 12px;
    text-align: center;
    text-decoration: none;

    font-size: 14px;
    font-weight: 600;

    color: #E9EFE8;

    &--bg {
      &-orange {
        background-color: #F55E21;
      }
      &-green {
        background-color: #608D00;
      }
      &-yellow {
        background-color: #ACBF05;
      }
    }
  }
}

.home-visit-text {
  font-size: 36px;
  font-weight: 700;
  font-family: Akrobat;

  color: #434343;
}