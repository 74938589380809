.breadcrumbs {
  margin-bottom: 15px;

  @media (min-width: 1024px) {
    margin-bottom: 20px;
  }

  & a, & span {
    color: #C2C2C2;
    text-decoration: none;
    font-size: 14px;
  }

  & span {
    opacity: .5;

    margin-right: 5px;
    margin-left: 5px;
  }
}

