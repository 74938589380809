.doctor-info {
  display: flex;
  flex-direction: column;
  align-items:center;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  &__img {
    flex: 0 0 auto;
    width: 260px;
    height: 260px;

    margin-bottom: 20px;

    @media (min-width: 1024px) {
      margin-right: 30px;
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__text {
    margin-bottom: 5px;

    font-size: 22px;
    font-weight: 600;
  }

  &__name {
    margin-top: 0;
    margin-bottom: 25px;

    font-family: 'Akrobat', Arial, sans-serif;
    font-weight: 600;
    font-size: 36px;
    color: $first-color;
  }

  &__descr {
    margin-top: 25px;
    margin-bottom: 0;

    font-size: 18px;
  }
}
