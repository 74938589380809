.article-card {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  border: 2px solid $third-color;

  text-decoration: none;
  color: $font-color;

  &__img {
    width: 100%;

    display: flex;
    justify-content: center;

    background: url('../images/other/pattern.png') repeat;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__description {
    height: 100%;

    display: flex;
    flex-direction: column;

    padding: 15px;

    background-color: #fff;

    @media (min-width: 768px) {
      padding: 40px 40px 20px;
    }

    &_green {
      background-color: $third-color;
    }
  }

  &__date {
    margin-bottom: 30px;

    font-size: 14px;
    font-weight: 600;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 20px;

    height: 92px;

    overflow-y: hidden;
  }

  &__link {
    margin-top: auto;

    font-size: 18px;
    font-weight: 600;
    color: $fourth-color;
  }
}