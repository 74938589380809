.doctor-to-home {
  overflow: hidden;

  &__scheme {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items:center;

    margin-bottom: 25px;
    padding-bottom: 40px;

    border-bottom: 4px solid $third-color;

    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-around;
    }
  }

  &__arrow {
    width: 180px;
    height: 73px;

    margin-top: 80px;
    margin-bottom: 80px;

    transform: rotate(90deg);

    background: url('../svg/doctor-to-home/arrow.svg') center/contain no-repeat;

    @media (min-width: 1024px) {
      transform: rotate(0deg);

      margin-right: 20px;
      margin-bottom: 0;
      margin-top: 0;

    }

    &_first,
    &_last {
      position: absolute;
      top: 0;
      bottom: 40px;
      margin: auto 0;
      z-index: -1;
    }

    &_first {
      right: calc(100% - 35px);
    }

    &_last {
      left: calc(100% - 35px);
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items:center;

    margin-bottom: 20px;

    @media (min-width: 1024px) {
      margin-right: 20px;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  &__pic {
    width: 176px;
    height: 167px;

    margin-bottom: 10px;

    &_call {
      background: url('../svg/doctor-to-home/call.svg') center/contain no-repeat;
    }

    &_operator {
      background: url('../svg/doctor-to-home/operator.svg') center/contain no-repeat;
    }

    &_car {
      background: url('../svg/doctor-to-home/car.svg') center/contain no-repeat;
    }
  }

  &__text {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
