.step {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;

  @media (min-width: 1024px) {
    grid-template-columns: 40% 1fr;
  }

  &_mb {
    margin-bottom: 15px;

    @media (min-width: 1024px) {
      margin-bottom: 50px;
    }
  }

  &__pic {
    display: none;

    @media (min-width: 1024px) {
      display: block;

      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center top;
      }
    }
  }

  &__list {
    @include clearList();
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item {
    margin-bottom: 15px;

    @media (min-width: 1024px) {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__num {
    display: inline-block;
    margin-bottom: 10px;
    color: $fourth-color;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 20px;

    font-family: 'Akrobat', sans-serif;
    font-size: 24px;
    color: $first-color;

    @media (min-width: 768px) {
      font-size: 36px;
    }
  }

  &__text {

    strong {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
