.price {
  width: 100%;
  margin-bottom: 30px;
  font-size: 18px;
  @media (min-width: 1024px) {
    margin-bottom: 60px;
  }
  &__row {
    position: relative;
    display: flex;
    margin-top: -2px;
    padding: 15px 25px;
    background-color: white;
    border: 2px solid $third-color;
    color: $font-color;
    text-decoration: none;
    &:first-child {
      margin-top: 0;
    }
    @media (min-width: 768px) {
      padding: 15px 40px;
    }
  }
  &__service {
    margin: 0 15px 0 0;
    font-size: 14px;
    @media (min-width: 576px) {
      margin-right: 30px;
      font-size: 18px;
    }
  }
  &__cost {
    flex: 0 0 auto;
    min-width: 70px;
    margin: 0 0 0 auto;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #FF6230;
    @media (min-width: 576px) {
      min-width: 100px;
      font-size: 20px;
    }
  }
  &__stock {
    margin: 0 auto;
    font-size: 14px;
    font-weight: 600;
    color: $fourth-color;
    @media (min-width: 576px) {
      font-size: 20px;
    }
  }
}