button {
  cursor: pointer;

  padding: 0;

  border: none;
  outline: none;
  background-color: transparent;
}

.button {
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5px;
  background-color: #fff;

  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;

  transition: background-color .1s ease-in;

  &_first {
    background-color: $first-color;
    color: #fff;

    @media (min-width: 992px) {
      &:hover {
        background-color: $third-color;
        color: $fourth-color;

        transition: background-color .1s ease-in;
      }
    }
  }

  &_second {
    color: $fourth-color;
    border: 2px solid $third-color;
    transition: all .1s ease-in;

    @media (min-width: 992px) {
      &:hover {
        background-color: $first-color;
        color: #fff;

        transition: all .1s ease-in;
      }
    }
  }

  &_salad {
    background-color: $third-color;
    border: 2px solid $third-color;

    color: $fourth-color;
    transition: all .1s ease-in;

    @media (min-width: 992px) {
      &:hover {
        background-color: $first-color;
        color: #fff;

        transition: all .1s ease-in;
      }
    }
  }

  &_red {
    background-color: $fourth-color;
    border: 2px solid $fourth-color;

    color: white;
    transition: all .1s ease-in;

    @media (min-width: 992px) {
      &:hover {
        background-color: $first-color;
        border: 2px solid $first-color;
        color: #fff;

        transition: all .1s ease-in;
      }
    }
  }

  &_ml-auto {
    margin-left: auto;
  }

  &[disabled] {
    pointer-events: none;
    background-color: lightgray;
    border-color: lightgray;

    color: $font-color
  }

  &_300 {
    max-width: 300px;
  }

  &_mb {
    margin-bottom: 30px;

    @media (min-width: 576px) {
      margin-bottom: 60px;
    }
  }
}
