.text {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: initial;

  &_margin {
    margin-top: 15px;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
      margin-top: 35px;
      margin-bottom: 50px;
    }
  }

  &_green {
    color: $first-color;
  }

  &_bold {
    font-weight: 600;
  }

  &_m-long-liver {
    margin-top: 15px;
    margin-bottom: 40px;
  }

  &__link {
    color: $first-color;
  }

  p {
    margin-top: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
