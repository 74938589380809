.map {
  display: flex;
  flex-direction: column;

  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 20px;
  }
  @media (min-width: 1200px) {
    margin-top: 20px;
    flex-direction: initial;
  }

  &__title {
    width: 100%;

    margin-top: 0;
    margin-bottom: 20px;

    color: $first-color;

    @media (min-width: 1200px) {
      max-width: 540px;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: 15px;

    border: 2px solid $third-color;
    border-bottom: none;

    font-size: $font-color;
    font-weight: 600;

    @media (min-width: 1200px) {
      border-right: none;
      padding: 40px;
    }
  }

  &__text {
    width: 100%;
    margin-bottom: 0;

    font-size: 18px;
    line-height: 24px;

    @media (min-width: 1200px) {
      max-width: 540px;
      font-size: 20px;
    }

    p {
      margin: 0 0 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__img {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 auto;

    width: 100%;
    height: 400px;

    border: 2px solid $third-color;
    border-top: none;

    @media (min-width: 1200px) {
      width: 700px;

      border: none;
    }
  }
}