.content {
  width: 100%;
  max-width: 1440px;

  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 1500px) {
    padding-left: 0;
    padding-right: 0;
  }

  &__btn-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: 'Inter', Arial, sans-serif;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    & > * {
      margin-bottom: 15px;

      @media (min-width: 768px) {
        max-width: 220px;

        margin-bottom: 0;
        margin-right: 30px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
