.about-us {
  position: relative;
  display: flex;
  flex-direction: column;

  margin-bottom: 100px;

  border: 2px solid $third-color;

  @media (min-width: 1500px) {
    flex-direction: initial;
    height: 540px;
  }

  &__video {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    background: url(../images/other/pattern.png) 50%/contain repeat;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
    @media (min-width: 1200px) {
      padding-top: 36.25%;
    }
    @media (min-width: 1500px) {
      max-width: 580px;
      height: 100%;
    }

    iframe {
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-width: 0;
      outline-width: 0;
    }


    video {
      width: 100%;
      height: calc(100vw - 30px);

      object-fit: contain;
      object-position: center;

      @media (min-width: 768px) {
        width: initial;
        height: 400px;
      }
      @media (min-width: 1500px) {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__description {
    padding: 15px;
    border-bottom: 2px solid $third-color;

    @media (min-width: 1500px) {
      width: 480px;
      padding: 30px 45px 80px 60px;
      border-bottom: none;

      flex: 0 0 auto;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 25px;

    color: $first-color;

    @media (min-width: 1500px) {
      margin-bottom: 35px;
    }
  }

  &__text {
    margin-top: 0;
    margin-bottom: 20px;

    letter-spacing: 0;
    line-height: 1.6;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      margin: 0;
    }

    &_description {

      @media (min-width: 1500px) {
        max-height: 305px;
        overflow-y: hidden;
      }
    }
  }

  &__btn {
    position: absolute;
    top: 100%;
    left: -2px;

    width: 230px;

    @media (min-width: 1500px) {
      left: initial;
    }
  }

  &__info {
    padding: 15px;

    @media (min-width: 1500px) {
      padding: 30px 45px 80px 30px;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__btn-map {
    width: 100%;
    font-size: 12px;

    @media (min-width: 360px) {
      width: 280px;
      font-size: 14px;
    }
  }
}