.advantages {
  width: 100%;

  margin-bottom: 30px;

  display:flex;
  justify-content:space-between;

  font-family: 'Akrobat', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0;

  @media (min-width: 992px) {
    margin-bottom: 70px;
  }

  &__block {
    width: 95px;
    background-color: $third-color;
  }

  &__block-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 1100px;
    flex: 1 1 auto;

    padding-left: 10px;
    padding-right: 10px;

    background-color:#fff;

    @media (min-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  
  &__title {
    margin-top: 0;
    margin-bottom: 15px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 28px;
      margin-bottom: 28px;
    }
    @media (min-width: 992px) {
      font-size: 36px;
    }
  }

  &__list {
    width: 100%;

    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: 1201px) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    &_column {
      align-items: flex-start;
    }

    &_main-page {
      max-width: 830px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;

      @media (min-width: 576px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px 50px;
      }

      .advantages {

        &__item {
          margin: 0;
          justify-content: initial;
        }

        &__text {
          max-width: initial;
        }
      }
    }
  }

  &__item {
    display: flex;
    justify-content:center;
    align-items: flex-end;

    width: 100%;

    margin-left: 0;
    margin-right: 0;

    @media (min-width: 768px) {
      width: initial;

      margin-left: 15px;
      margin-right: 15px;

      justify-content: initial;
    }

    &_column {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__num {
    margin-right: 10px;

    font-size: 36px;
    color: $fourth-color;

    @media (min-width: 768px) {
      font-size: 48px;
    }
    @media (min-width: 992px) {
      font-size: 80px;
    }

    span {
      font-size: 14px;

      @media (min-width: 768px) {
        font-size: 18px;
      }
      @media (min-width: 992px) {
        font-size: 28px;
      }
    }
  }

  &__text {
    margin-bottom: 10px;

    max-width: 200px;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 18px;
    }
    @media (min-width: 992px) {
      font-size: 28px;
    }

    span {
      color: $fourth-color;
    }
  }
}