.stock-card {
  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: white;
  border: 2px solid $third-color;

  text-decoration: none;
  color: $font-color;

  &__head {
    position: relative;
    height: 210px;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
    }
  }

  &__bookmark {
    position: absolute;
    top: 0;
    left: 0;

    width: 125px;
    height: 50px;

    display: flex;
    align-items: center;

    padding-left: 15px;

    background-color: $fourth-color;
    clip-path: polygon(0 0, 100% 0%, calc(100% - 23px) 100%, 0% 100%);

    font-family: 'Akrobat', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: white;
    text-transform: uppercase;
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    padding: 15px;

    @media (min-width: 1024px) {
      padding: 25px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 20px;

    font-size: 28px;
    font-family: 'Akrobat', Arial, sans-serif;
    font-weight: 700;
    line-height: 1;
    color: $fourth-color;

    @media (min-width: 768px) {
      margin-bottom: 35px;
      font-size: 36px;
    }

    &_little {
      font-size: 28px;
    }
  }

  &__text {
    margin-top: 0;
    margin-bottom: 20px;

    font-size: 18px;
    font-weight: 600;
    line-height: 1;
  }

  &__more {
    margin-top: auto;

    font-size: 14px;
    font-weight: 600;
    color: $fourth-color;
  }
}
