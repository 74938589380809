.amenities {

  &__hr {
    margin-bottom: 20px;
  }

  &__description {
    margin-bottom: 30px;

    @media (min-width: 768px) {
      margin-bottom: 60px;
    }
  }

  &__spoilers {
    margin-bottom: 30px;

    @media (min-width: 576px) {
      margin-bottom: 60px;
    }
  }
}
