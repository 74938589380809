.costs {
  position: relative;
  padding-top: 30px;

  @media (min-width: 768px) {
    padding-top: 45px;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;

    width: 100%;
    height: 160px;
  }

  &::before {
    z-index: -2;
    background: url('../images/patterns/orange.png') center/40%;
  }

  &::after {
    z-index: -1;
    background: radial-gradient(62.56% 114.43% at 35.74% 34.9%, #FF6230 0%, black 100%);
    opacity: .3;
  }

  &_mb {
    margin-bottom: 33px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 15px;

    color: white;
    text-align: center;

    @media (min-width: 1024px) {
      margin-bottom: 30px;
    }
  }
}
