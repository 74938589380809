.subtitle {
  margin: 0;
  opacity: .5;

  font-family: 'Akrobat', Arial, sans-serif;
  font-size: 18px;

  @media (min-width: 992px) {
    font-size: 28px;
  }
}
