$first-color: #679100;        // зеленый
$second-color: #a4ba04;       // темно-салатовый
$third-color: #e9efe8;        // салатовый
$fourth-color: #f55e21;       // рыжий

$font-color: #434343;         // черный



// Соотношения сторон картинок для сайта
// --------------------------------------------
// Главный слайдер - 1,57
// Карточки на главной, под слайдером - 1,7
// Карточки врачей в слайдере (фото врача) - 1,14 (карточка может тянуться по ширине, но фото всегда будет определенной ширины, по бокам будет виден паттерн)
// Видео на главной - 1,03
// Карточки статей на главной - 1,7
// Горизонтальные карточки статей - 1.7
// Баннеры акций - 6:1 (ПК), 330:200 (мобилки, ниже 480px)
// Обложка статьи - 3,6