.droppers-available {
  position: relative;
  padding-top: 30px;
  @media (min-width: 768px) {
    padding-top: 45px;
  }
  &::before,
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 380px;
  }
  &::before {
    z-index: -2;
    background: url('../images/patterns/green.png') center/40%;
  }
  &::after {
    z-index: -1;
    background: radial-gradient(62.56% 114.43% at 35.74% 34.9%, #A4BA04 0%, black 100%);
    opacity: .3;
  }
  &_mb {
    margin-bottom: 20px;
  }
  &__title {
    margin: 0 auto 30px auto;
    color: white;
    text-align: center;
  }
  &__warning {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    opacity: 0.2;
  }
  &__list {
    margin: 0 auto;
    padding: 0;
    list-style: none;
  }
  &__item {
    margin-bottom: 20px;
    background: url('../../images/droppers/back.png') right bottom no-repeat #E9EFE8;
  }
  &__header {
    padding: 160px 40px 20px;
  }
  &__subtitle {
    margin: 0;
    margin-bottom: 15px;
    font-size: 36px;
    font-weight: 700;
    color: #F55E21;
  }
  &__desc {
    margin: 0;
    line-height: 150%;
  }
  &__sub-list {
    margin-bottom: 35px;
    padding-left: 2%;
    list-style: disc;
  }
  &__sub-item {
    color: #434343;
    font-weight: 600;
    margin-top: 15px;
    &::marker {
      color: #ff0000;
    }
  }
  &__body {
    padding: 0 40px 20px 40px;
    box-shadow: 0px -19px 40px #ffffff66;
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    padding: 0 40px 40px 40px;
  }
  &__column {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    &-text {
      margin-bottom: 5px;
    }
    &-number {
      font-size: 36px;
      color: #F55E21;
      font-weight: 700;
      &_bg-number {
        margin-left: auto;
        font-size: 50px;
      }
    }
    &_one-row {
      margin-left: auto;
      margin-right: 0;
      font-size: 62px;
      color: #FF6230;
      font-weight: 700;
    }
    &_last-column {
      flex: 1 1 0%;
      margin-right: 0;
      justify-content: center;
    }
    &-item {
      display: flex;
      align-items: center;
    }
  }
  &__column-Price {
    display: flex;
    flex-direction: column;
    & small {
      font-size: 18px;
      display: block;
      margin-bottom: 5px;
    }
  }
  @media (max-width: 1500px) {
    &__column-item {
      align-items: end;
      flex-direction: column;
    }
  }
  @media (max-width: 1200px) {
    &__column {
      margin-bottom: 20px;
      &_last-column {
        flex: 1 1 100%;
        margin-right: 0;
      }
      &_one-row {
        margin-left: 0;
      }
      &-number_bg-number {
        margin-left: inherit;
      }
    }
  }
  @media (max-width: 680px) {
    &__header {
      padding: 40px 40px 0px 40px;
    }
  }
  @media (max-width: 500px) {
    &__header {
      padding: 20px 40px 0px 40px;
      background-size: cover;
    }
    &__subtitle {
      font-size: 24px;
    }
    &__column-number {
      font-size: 24px;
    }
    &__column_one-row {
      font-size: 50px;
    }
  }
  @media (max-width: 376px) {
    &__sub-item {
      font-size: 16px;
    }
  }
}

.border-green {
  width: 100%;
  height: 2px;
  margin-top: 20px;
  background-color: #608D00;
}