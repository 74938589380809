.callback-banner {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 30px 30px;
  background: url('../images/other/callback-banner.jpg') center/cover no-repeat;

  &_mb {
    margin-bottom: 30px;
  }

  &__title {
    margin-bottom: 10px;
    text-align: center;
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: 30px;

    font-family: 'Akrobat', Arial, sans-serif;
    color: white;
    font-size: 24px;
    font-weight: 600;

    @media (min-width: 768px) {
      font-size: 28px;
    }
    @media (min-width: 1360px) {
      font-size: 45px;
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 470px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    & > * {
      margin-bottom: 10px;

      @media (min-width: 768px) {
        margin-right: 30px;
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }
}
