@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: normal;
  src: local('Inter'), url("../fonts/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  src: local('Inter'), url("../fonts/Inter-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  src: local('Inter'), url("../fonts/Inter-SemiBold.woff") format("woff");
}


@font-face {
  font-family: 'Akrobat';
  font-weight: 700;
  font-style: normal;
  src: local('Akrobat Bold'), url('../fonts/Akrobatbold.woff') format('woff');
}

@font-face {
  font-family: 'Akrobat';
  font-weight: 500;
  font-style: normal;
  src: local('Akrobat Bold'), url('../fonts/Akrobat_Semi_Bold.woff') format('woff');
}
