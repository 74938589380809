.ask-form {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 15px;

  border: 2px solid $third-color;

  @media (min-width: 768px) {
    padding: 50px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 20px;

    color: $fourth-color;
    font-size: 24px;

    @media (min-width: 576px) {
      font-size: 28px;
    }
    @media (min-width: 768px) {
      margin-bottom: 40px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    margin-top: auto;
  }

  &__input, &__textarea {
    margin-bottom: 2px;
    padding: 20px;

    border: none;
    background-color: $third-color;
    font-size: 14px;

    &::placeholder {
      font-weight: 600;
      font-size: 14px;
      color: $font-color;
    }
  }

  &__textarea {
    resize: none;
    height: 120px;
  }

  &__btn {
    background-color: $third-color;

    @media (min-width: 768px) {
      width: 300px;
    }

    &:hover {
      background-color: $first-color;
    }
  }
}