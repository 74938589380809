.navigation {
  height: 100%;

  display: flex;
  align-items: center;

  li {
    list-style-type: none;
  }

  &__burger {

    @media (min-width: 1200px) {
      display: none;
    }
  }

  &__content {
    display: none;

    position: absolute;
    top: calc(100% + 2px);
    left: 0;

    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    background-color: #fff;

    @media (min-width: 1200px) {
      overflow: initial;

      display: block;
      position: initial;

      min-height: auto;
      height: 100%;

      opacity: 1;
    }

    &.open {
      display: block;
    }
  }

  &__search {
    margin-bottom: 20px;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  &__other {
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  &__wrap-btn {
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: 992px) {
      display: flex;
      justify-content: space-between;
    }
    @media (min-width: 1200px) {
      display: none;
    }

    button {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: 992px) {
        margin-right: 20px;
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__list {
    @include clearList();

    display: block;

    @media (min-width: 1200px) {
      display: flex;

      height: 100%;
      margin-right: -8px;
    }

    h3 {
      margin: 0;
      font-size: 18px;

      @media (min-width: 1200px) {
        font-size: 14px;
      }
      @media (min-width: 1400px) {
        font-size: 16px;
      }
    }
  }

  &__item {
    cursor: pointer;
    position: relative;

    padding: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #fff;
    border-top: 2px solid $third-color;

    &:last-child {
      border-bottom: 2px solid $third-color;

      @media (min-width: 1200px) {
        margin-right: 0;
        border: none;
      }

      .navigation__link h3 {

        @media (min-width: 1200px) {
          padding-right: 0;
        }
      }
    }

    @media (min-width: 1200px) {
      height: 100%;

      padding-left: 8px;
      padding-right: 8px;

      align-items:center;
      border: none;

      &:hover {
        background-color: $fourth-color;

        &.navigation__item_drop {
          > a::before {
            @include arrow(5px, 2px, 'bottom', #ffffff);
          }
        }

        .navigation__link {
          color: #fff;
        }

        .navigation__dropdown {
          display: block;
        }
      }

      &:not(.navigation__item_drop) .navigation__link h3 {
        padding-right: 0;
      }
    }

    &.navigation__item_drop {

      > a::before {
        position: absolute;
        content: '';
        top: 5px;
        right: 10px;

        transition: transform .2s ease-in;

        @include arrow(10px, 2px, 'bottom', #DA3E0D);

        @media (min-width: 1200px) {
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto 0;

          @include arrow(5px, 2px, 'bottom', #DA3E0D);
        }
      }

      &.open {

        > a::before {
          transform: rotate(315deg);
          transition: transform .2s ease-in;
        }

        .navigation__link {
          display: block;
          margin-bottom: 20px;

          @media (min-width: 1200px) {
            margin-bottom: 0;
          }
        }

        .navigation__dropdown {
          display: block;
        }
      }
    }

  }

  &__item_level {

    @media (min-width: 1200px) {

      position: relative;

      &:hover {

        &.navigation__item_drop::before {
          @include arrow(5px, 2px, 'bottom', #ffffff);
        }

        .navigation__link {
          color: #fff;
        }

        .navigation__dropdown_level {
          display: block;
        }
      }

      &:not(.navigation__item_level_drop) .navigation__link h3 {
        padding-right: 0;
      }
    }

    &.navigation__item_level_drop {

      > a::before {
        position: absolute;
        content: '';
        top: 5px;
        right: 10px;

        transition: transform .2s ease-in;

        @include arrow(10px, 2px, 'bottom', #DA3E0D);

        @media (min-width: 1200px) {
          top: 0;
          bottom: 0;
          right: 14px;
          margin: auto 0;

          @include arrow(5px, 2px, 'right', #DA3E0D);
        }
      }

      &.open {

        > a::before {
          transform: rotate(315deg);
          transition: transform .2s ease-in;
        }

        .navigation__link {
          display: block;
          margin-bottom: 20px;

          @media (min-width: 1200px) {
            margin-bottom: 0;
          }
        }

        .navigation__dropdown_level {
          display: block;
        }
      }
    }

  }

  &__link {
    text-decoration: none;
    color: $font-color;

    @media (min-width: 1200px) {
      margin-bottom: 0;
    }

    h3 {
      padding-right: 12px;
    }
  }

  &__dropdown {
    display: none;
    position: relative;

    margin-left: 8px;

    padding: 10px 0;

    background-color: #fff;

    @media (min-width: 1200px) {
      position: absolute;
      content: '';
      top: 100%;

      margin-left: 0;

      background-color: $third-color;

      &:hover {
        display: block;
      }
    }

    &_button {
      position: relative;
    }

    h3 {
      display: inline-block;
      width: 100%;

      font-size: 16px;
      color: $font-color;
      white-space: nowrap;

      @media (min-width: 1200px) {
        padding: 8px 30px;

        &:hover {
          background-color: #a4ba04;
        }
      }
      @media (min-width: 1350px) {
        font-size: 16px;
      }
    }
  }

  &__dropdown_level {
    top: 0;
    left: 0;

    display: none;
    position: relative;
    padding: 10px 0;
    margin-left: 8px;

    background-color: #ffffff;

    @media (min-width: 1200px) {
      position: absolute;
      content: '';
      top: -10px;
      left: 100%;
      margin-left: 0;

      background-color: #D4E1D2;

      &:hover {
        display: block;
      }
    }

    h3 {
      display: inline-block;
      width: 100%;

      font-size: 16px;
      color: $font-color;
      white-space: nowrap;

      @media (min-width: 1200px) {
        padding: 8px 30px;

        &:hover {
          background-color: #a4ba04;
        }
      }
      @media (min-width: 1350px) {
        font-size: 16px;
      }
    }

  }

  &__dropdown-link {
    display: block;

    text-decoration: none;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 1200px) {
      margin-bottom: 0;
    }
  }
}
