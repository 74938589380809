.banner {
  position: relative;
  display: block;

  height: 200px;
  margin-bottom: 80px;

  text-decoration: none;
  color: $font-color;

  @media (min-width: 768px) {
    height: 400px;

    &:hover {

      img {
        transform: scale(1.2);
        transition: transform .2s ease-in;
      }
    }
  }

  &::before {
    position: absolute;
    content: '';
    top: 20px;
    right: 0;
    left: 0;
    margin :0 auto;
    z-index: 1;

    width: calc(100% - 40px);
    height: 100%;

    border: 2px solid $third-color;

    @media (min-width: 768px) {
      width: calc(100% - 80px);
      top: 40px;
    }
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, .5);
  }

  &__pic {
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    background: url('../images/other/pattern.png');

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;

      transition: transform .2s ease-in;
    }
  }

  &__info {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: calc(100% - 80px);
    height: calc(100% - 60px);

    color: white;

    @media (min-width: 768px) {
      width: calc(100% - 160px);
      height: calc(100% - 120px);

      top: 80px;
    }
  }

  &__title {
    margin: 0;
    max-height: 120px;
    overflow: hidden;
  }

  &__more {
    align-self: flex-end;
  }
}