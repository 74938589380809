@charset "UTF-8";
* {
  box-sizing: border-box; }

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.05em;
  color: #434343;
  overflow-x: hidden; }

main {
  flex: 1 1 auto; }

video {
  outline: none; }

input:focus,
textarea:focus,
select:focus {
  position: relative;
  outline: 2px solid #679100;
  z-index: 1; }

.mb {
  margin-bottom: 30px; }
  @media (min-width: 1200px) {
    .mb {
      margin-bottom: 60px; } }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  src: local("Inter"), url("../fonts/Inter-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local("Inter"), url("../fonts/Inter-Medium.woff") format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local("Inter"), url("../fonts/Inter-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'Akrobat';
  font-weight: 700;
  font-style: normal;
  src: local("Akrobat Bold"), url("../fonts/Akrobatbold.woff") format("woff"); }

@font-face {
  font-family: 'Akrobat';
  font-weight: 500;
  font-style: normal;
  src: local("Akrobat Bold"), url("../fonts/Akrobat_Semi_Bold.woff") format("woff"); }

.content {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 1500px) {
    .content {
      padding-left: 0;
      padding-right: 0; } }
  .content__btn-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', Arial, sans-serif; }
    @media (min-width: 768px) {
      .content__btn-wrap {
        flex-direction: row; } }
    .content__btn-wrap > * {
      margin-bottom: 15px; }
      @media (min-width: 768px) {
        .content__btn-wrap > * {
          max-width: 220px;
          margin-bottom: 0;
          margin-right: 30px; } }
      .content__btn-wrap > *:last-child {
        margin-right: 0; }

.link {
  cursor: pointer;
  margin-right: 30px;
  text-decoration: none;
  color: #434343; }
  .link:last-child {
    margin-right: 0; }
  .link_number {
    font-size: 19px;
    color: #f55e21;
    line-height: 1.2;
    margin-bottom: 3px; }
    @media (min-width: 992px) {
      .link_number {
        margin-bottom: 0;
        font-size: 28px; }
        .link_number:hover {
          color: #a4ba04; } }
  .link_search {
    display: none; }
    @media (min-width: 992px) {
      .link_search {
        position: relative;
        display: initial;
        padding-left: 32px;
        text-transform: lowercase; }
        .link_search::before {
          position: absolute;
          content: '';
          left: 0;
          width: 25px;
          height: 25px;
          background: url("../svg/icons/search.svg") center/contain no-repeat; } }
  .link_feedback {
    display: none; }
    @media (min-width: 1200px) {
      .link_feedback {
        position: relative;
        display: initial;
        padding-left: 32px;
        text-transform: lowercase; }
        .link_feedback::before {
          position: absolute;
          content: '';
          left: 0;
          width: 25px;
          height: 25px;
          background: url("../svg/icons/feedback.svg") center/contain no-repeat; } }
  .link_address {
    font-size: 13px;
    text-align: center; }
    @media (min-width: 992px) {
      .link_address {
        position: relative;
        padding-left: 35px;
        font-size: 18px;
        text-align: left; }
        .link_address::before {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto 0;
          width: 25px;
          height: 25px;
          background: url("../svg/icons/point.svg") center/contain no-repeat;
          animation: point 1s ease-in-out infinite; } }

@keyframes point {
  50% {
    top: -10px; }
  100% {
    top: 0px; } }

.title {
  font-family: 'Akrobat', sans-serif;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .title {
      font-size: 36px;
      margin: 0; } }
  .title_no-margin {
    margin: 0; }
  .title_red {
    color: #f55e21; }
  .title_green {
    color: #679100; }
  .title_white {
    color: white; }
  .title_center {
    text-align: center; }
  .title_h2 {
    margin: 0; }
  .title_h3 {
    margin: 0;
    font-size: 24px; }
    @media (min-width: 576px) {
      .title_h3 {
        font-size: 28px; } }
  .title__hr {
    width: 100%;
    height: 2px;
    background: #e9efe8;
    border: none; }

.subtitle {
  margin: 0;
  opacity: .5;
  font-family: 'Akrobat', Arial, sans-serif;
  font-size: 18px; }
  @media (min-width: 992px) {
    .subtitle {
      font-size: 28px; } }

.text {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: initial; }
  .text_margin {
    margin-top: 15px;
    margin-bottom: 20px; }
    @media (min-width: 1024px) {
      .text_margin {
        margin-top: 35px;
        margin-bottom: 50px; } }
  .text_green {
    color: #679100; }
  .text_bold {
    font-weight: 600; }
  .text_m-long-liver {
    margin-top: 15px;
    margin-bottom: 40px; }
  .text__link {
    color: #679100; }
  .text p {
    margin-top: 0;
    margin-bottom: 20px; }
    .text p:last-child {
      margin-bottom: 0; }

.list {
  display: flex;
  flex-direction: column; }
  .list_red .list__item::before {
    background-color: #f55e21; }
  .list__title {
    margin-top: 0;
    margin-bottom: 25px; }
    .list__title_center {
      margin: 0 auto 25px auto; }
    .list__title--align-center {
      text-align: center;
      text-transform: inherit; }
    .list__title_green {
      color: #679100; }
    .list__title_orange {
      position: relative;
      margin-bottom: 50px;
      color: #f55e21; }
      .list__title_orange::before {
        position: absolute;
        content: '';
        bottom: -20px;
        transform: translateX(-50%);
        margin-left: 50%;
        width: 225px;
        height: 6px;
        background: url("../svg/icons/king.svg") center/contain no-repeat; }
        @media (min-width: 768px) {
          .list__title_orange::before {
            width: 450px;
            height: 13px; } }
  .list__subtitle {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600; }
  .list__list {
    margin: 0;
    padding: 0;
    list-style: none; }
    .list__list_mb {
      margin-bottom: 40px; }
  .list__item {
    position: relative;
    margin-bottom: 15px;
    padding-left: 30px; }
    .list__item:last-child {
      margin-bottom: 0; }
    .list__item::before {
      position: absolute;
      content: '';
      top: 5px;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: #e9efe8; }
    .list__item_check {
      font-weight: 600; }
      .list__item_check::before {
        width: 25px;
        height: 15px;
        background: url("../svg/icons/two-check.svg") center/contain no-repeat; }
  .list__descr {
    font-size: 18px; }
  .list__text_normal {
    text-transform: inherit;
    text-align: center; }

button {
  cursor: pointer;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent; }

.button {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color .1s ease-in; }
  .button_first {
    background-color: #679100;
    color: #fff; }
    @media (min-width: 992px) {
      .button_first:hover {
        background-color: #e9efe8;
        color: #f55e21;
        transition: background-color .1s ease-in; } }
  .button_second {
    color: #f55e21;
    border: 2px solid #e9efe8;
    transition: all .1s ease-in; }
    @media (min-width: 992px) {
      .button_second:hover {
        background-color: #679100;
        color: #fff;
        transition: all .1s ease-in; } }
  .button_salad {
    background-color: #e9efe8;
    border: 2px solid #e9efe8;
    color: #f55e21;
    transition: all .1s ease-in; }
    @media (min-width: 992px) {
      .button_salad:hover {
        background-color: #679100;
        color: #fff;
        transition: all .1s ease-in; } }
  .button_red {
    background-color: #f55e21;
    border: 2px solid #f55e21;
    color: white;
    transition: all .1s ease-in; }
    @media (min-width: 992px) {
      .button_red:hover {
        background-color: #679100;
        border: 2px solid #679100;
        color: #fff;
        transition: all .1s ease-in; } }
  .button_ml-auto {
    margin-left: auto; }
  .button[disabled] {
    pointer-events: none;
    background-color: lightgray;
    border-color: lightgray;
    color: #434343; }
  .button_300 {
    max-width: 300px; }
  .button_mb {
    margin-bottom: 30px; }
    @media (min-width: 576px) {
      .button_mb {
        margin-bottom: 60px; } }

.video {
  cursor: pointer;
  position: relative; }
  .video__play {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    width: 92px;
    height: 92px;
    background: url("../svg/icons/play-button.svg") center/contain no-repeat; }
  .video.play .video__play {
    display: none; }

.pagination {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex; }
  .pagination__item {
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #e9efe8;
    margin-left: -2px; }
    .pagination__item:first-child {
      margin-left: 0; }
    .pagination__item:hover {
      background-color: #679100;
      color: white; }
      .pagination__item:hover .pagination__link_prev::before, .pagination__item:hover .pagination__link_next::before {
        border-color: white; }
    .pagination__item_active {
      pointer-events: none;
      background-color: #e9efe8; }
    .pagination__item_prev .pagination__link::before, .pagination__item_next .pagination__link::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
    .pagination__item_prev .pagination__link::before {
      left: 7px;
      width: 12px;
      height: 12px;
      border-top: 2px solid #f55e21;
      border-right: 2px solid #f55e21;
      transform: rotate(-135deg); }
    .pagination__item_next .pagination__link::before {
      right: 7px;
      width: 12px;
      height: 12px;
      border-top: 2px solid #f55e21;
      border-right: 2px solid #f55e21;
      transform: rotate(45deg); }
    .pagination__item_disabled {
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.1); }
      .pagination__item_disabled .pagination__link::before {
        border-color: gray; }
  .pagination__link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
    font-weight: 500; }

.header {
  margin-bottom: 20px;
  flex: 0 0 auto;
  font-weight: 600;
  background-color: #fff; }

.footer {
  flex: 0 0 auto;
  margin-top: -2px;
  border-top: 2px solid #e9efe8; }
  @media (min-width: 1200px) {
    .footer {
      margin-top: 0; } }
  .footer__content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 600; }
    @media (min-width: 1500px) {
      .footer__content {
        flex-direction: initial; } }
  .footer__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-bottom: 30px;
    padding-top: 15px;
    padding-right: 30px; }
    @media (min-width: 1200px) {
      .footer__info {
        padding-right: 100px; } }
    @media (min-width: 1500px) {
      .footer__info {
        margin-bottom: 0;
        padding: 40px 80px 40px 0;
        border-right: 2px solid #e9efe8; } }
    .footer__info-row {
      display: flex;
      flex-wrap: wrap; }
    .footer__info-banner-box {
      margin-left: 15px;
      align-self: center; }
    .footer__info-banner-img {
      margin-right: 5px; }
  .footer__logo {
    margin-bottom: 25px; }
    @media (min-width: 1500px) {
      .footer__logo {
        margin-bottom: 35px; } }
  .footer__navigation {
    margin-bottom: 30px; }
    @media (min-width: 1500px) {
      .footer__navigation {
        margin-bottom: 60px; } }
  .footer__list {
    margin: 0;
    padding: 0;
    list-style: none; }
    @media (min-width: 576px) {
      .footer__list {
        display: flex;
        flex-wrap: wrap; } }
    @media (min-width: 992px) {
      .footer__list {
        flex-wrap: nowrap; } }
  .footer__item {
    margin-bottom: 5px; }
    @media (min-width: 576px) {
      .footer__item {
        width: calc(100% / 3); } }
    @media (min-width: 992px) {
      .footer__item {
        width: initial;
        margin-right: 15px;
        margin-bottom: 0; } }
  .footer__link {
    text-decoration: none;
    color: #434343; }
    @media (min-width: 992px) {
      .footer__link:hover {
        color: #a4ba04; } }
    .footer__link h3 {
      margin: 0; }
  .footer__text {
    margin: 0;
    font-size: 14px;
    color: #C2C2C2; }
    @media (min-width: 576px) {
      .footer__text {
        font-size: 16px; } }
  .footer__media {
    width: 100%;
    padding-bottom: 30px; }
    @media (min-width: 768px) {
      .footer__media {
        max-width: 500px; } }
    @media (min-width: 1500px) {
      .footer__media {
        padding: 40px 80px 40px 80px; } }
    @media (min-width: 1700px) {
      .footer__media {
        padding-right: 0; } }
  .footer__social-links {
    margin-top: 25px; }

.logo {
  display: inline-block;
  width: 150px;
  height: 27px;
  background: url("../svg/logo/logo-mobil.svg") center/contain no-repeat; }
  .logo:hover {
    background-image: url("../svg/logo/logo-mobil.svg"); }
  @media (min-width: 345px) {
    .logo {
      width: 168px;
      height: 31px; } }
  @media (min-width: 576px) {
    .logo {
      width: 201px;
      height: 37px; } }
  @media (min-width: 768px) {
    .logo {
      width: 460px;
      height: 103px;
      background-image: url("../svg/logo/logo-action.svg"); }
      .logo:hover {
        background-image: url("../svg/logo/logo-action.svg"); } }

.burger {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 22px; }
  .burger.open .burger__icon {
    width: 30px;
    height: 0;
    transition: height .2s ease-in, width .2s ease-in; }
    .burger.open .burger__icon::before {
      top: 10px;
      width: 30px;
      transform: rotate(45deg);
      transition: transform .2s .2s ease-in, width .2s ease-in, top .2s ease-in; }
    .burger.open .burger__icon::after {
      bottom: 10px;
      width: 30px;
      transform: rotate(-45deg);
      transition: transform .2s .2s ease-in, width .2s ease-in, bottom .2s ease-in; }
  .burger__text {
    margin-right: 8px;
    text-transform: lowercase;
    font-size: 14px;
    font-weight: 600; }
  .burger__icon {
    width: 40px;
    height: 2px;
    transition: height .2s ease-in, width .2s ease-in;
    background-color: #f55e21; }
    .burger__icon::before, .burger__icon::after {
      position: absolute;
      content: '';
      right: 0;
      width: 40px;
      height: 2px;
      background-color: #f55e21;
      transition: transform .2s .4s ease-in, width .2s .2s ease-in; }
    .burger__icon::before {
      top: 0;
      transition: top .2s ease-in; }
    .burger__icon::after {
      bottom: 0;
      transition: bottom .2s ease-in; }

.navigation {
  height: 100%;
  display: flex;
  align-items: center; }
  .navigation li {
    list-style-type: none; }
  @media (min-width: 1200px) {
    .navigation__burger {
      display: none; } }
  .navigation__content {
    display: none;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff; }
    @media (min-width: 1200px) {
      .navigation__content {
        overflow: initial;
        display: block;
        position: initial;
        min-height: auto;
        height: 100%;
        opacity: 1; } }
    .navigation__content.open {
      display: block; }
  .navigation__search {
    margin-bottom: 20px; }
    @media (min-width: 1200px) {
      .navigation__search {
        display: none; } }
  .navigation__other {
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 1200px) {
      .navigation__other {
        display: none; } }
  .navigation__wrap-btn {
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 992px) {
      .navigation__wrap-btn {
        display: flex;
        justify-content: space-between; } }
    @media (min-width: 1200px) {
      .navigation__wrap-btn {
        display: none; } }
    .navigation__wrap-btn button {
      margin-bottom: 20px; }
      .navigation__wrap-btn button:last-child {
        margin-bottom: 0; }
      @media (min-width: 992px) {
        .navigation__wrap-btn button {
          margin-right: 20px;
          margin-bottom: 0; }
          .navigation__wrap-btn button:last-child {
            margin-right: 0; } }
  .navigation__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block; }
    @media (min-width: 1200px) {
      .navigation__list {
        display: flex;
        height: 100%;
        margin-right: -8px; } }
    .navigation__list h3 {
      margin: 0;
      font-size: 18px; }
      @media (min-width: 1200px) {
        .navigation__list h3 {
          font-size: 14px; } }
      @media (min-width: 1400px) {
        .navigation__list h3 {
          font-size: 16px; } }
  .navigation__item {
    cursor: pointer;
    position: relative;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-top: 2px solid #e9efe8; }
    .navigation__item:last-child {
      border-bottom: 2px solid #e9efe8; }
      @media (min-width: 1200px) {
        .navigation__item:last-child {
          margin-right: 0;
          border: none; } }
      @media (min-width: 1200px) {
        .navigation__item:last-child .navigation__link h3 {
          padding-right: 0; } }
    @media (min-width: 1200px) {
      .navigation__item {
        height: 100%;
        padding-left: 8px;
        padding-right: 8px;
        align-items: center;
        border: none; }
        .navigation__item:hover {
          background-color: #f55e21; }
          .navigation__item:hover.navigation__item_drop > a::before {
            width: 5px;
            height: 5px;
            border-top: 2px solid #ffffff;
            border-right: 2px solid #ffffff;
            transform: rotate(135deg); }
          .navigation__item:hover .navigation__link {
            color: #fff; }
          .navigation__item:hover .navigation__dropdown {
            display: block; }
        .navigation__item:not(.navigation__item_drop) .navigation__link h3 {
          padding-right: 0; } }
    .navigation__item.navigation__item_drop > a::before {
      position: absolute;
      content: '';
      top: 5px;
      right: 10px;
      transition: transform .2s ease-in;
      width: 10px;
      height: 10px;
      border-top: 2px solid #DA3E0D;
      border-right: 2px solid #DA3E0D;
      transform: rotate(135deg); }
      @media (min-width: 1200px) {
        .navigation__item.navigation__item_drop > a::before {
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto 0;
          width: 5px;
          height: 5px;
          border-top: 2px solid #DA3E0D;
          border-right: 2px solid #DA3E0D;
          transform: rotate(135deg); } }
    .navigation__item.navigation__item_drop.open > a::before {
      transform: rotate(315deg);
      transition: transform .2s ease-in; }
    .navigation__item.navigation__item_drop.open .navigation__link {
      display: block;
      margin-bottom: 20px; }
      @media (min-width: 1200px) {
        .navigation__item.navigation__item_drop.open .navigation__link {
          margin-bottom: 0; } }
    .navigation__item.navigation__item_drop.open .navigation__dropdown {
      display: block; }
  @media (min-width: 1200px) {
    .navigation__item_level {
      position: relative; }
      .navigation__item_level:hover.navigation__item_drop::before {
        width: 5px;
        height: 5px;
        border-top: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
        transform: rotate(135deg); }
      .navigation__item_level:hover .navigation__link {
        color: #fff; }
      .navigation__item_level:hover .navigation__dropdown_level {
        display: block; }
      .navigation__item_level:not(.navigation__item_level_drop) .navigation__link h3 {
        padding-right: 0; } }
  .navigation__item_level.navigation__item_level_drop > a::before {
    position: absolute;
    content: '';
    top: 5px;
    right: 10px;
    transition: transform .2s ease-in;
    width: 10px;
    height: 10px;
    border-top: 2px solid #DA3E0D;
    border-right: 2px solid #DA3E0D;
    transform: rotate(135deg); }
    @media (min-width: 1200px) {
      .navigation__item_level.navigation__item_level_drop > a::before {
        top: 0;
        bottom: 0;
        right: 14px;
        margin: auto 0;
        width: 5px;
        height: 5px;
        border-top: 2px solid #DA3E0D;
        border-right: 2px solid #DA3E0D;
        transform: rotate(45deg); } }
  .navigation__item_level.navigation__item_level_drop.open > a::before {
    transform: rotate(315deg);
    transition: transform .2s ease-in; }
  .navigation__item_level.navigation__item_level_drop.open .navigation__link {
    display: block;
    margin-bottom: 20px; }
    @media (min-width: 1200px) {
      .navigation__item_level.navigation__item_level_drop.open .navigation__link {
        margin-bottom: 0; } }
  .navigation__item_level.navigation__item_level_drop.open .navigation__dropdown_level {
    display: block; }
  .navigation__link {
    text-decoration: none;
    color: #434343; }
    @media (min-width: 1200px) {
      .navigation__link {
        margin-bottom: 0; } }
    .navigation__link h3 {
      padding-right: 12px; }
  .navigation__dropdown {
    display: none;
    position: relative;
    margin-left: 8px;
    padding: 10px 0;
    background-color: #fff; }
    @media (min-width: 1200px) {
      .navigation__dropdown {
        position: absolute;
        content: '';
        top: 100%;
        margin-left: 0;
        background-color: #e9efe8; }
        .navigation__dropdown:hover {
          display: block; } }
    .navigation__dropdown_button {
      position: relative; }
    .navigation__dropdown h3 {
      display: inline-block;
      width: 100%;
      font-size: 16px;
      color: #434343;
      white-space: nowrap; }
      @media (min-width: 1200px) {
        .navigation__dropdown h3 {
          padding: 8px 30px; }
          .navigation__dropdown h3:hover {
            background-color: #a4ba04; } }
      @media (min-width: 1350px) {
        .navigation__dropdown h3 {
          font-size: 16px; } }
  .navigation__dropdown_level {
    top: 0;
    left: 0;
    display: none;
    position: relative;
    padding: 10px 0;
    margin-left: 8px;
    background-color: #ffffff; }
    @media (min-width: 1200px) {
      .navigation__dropdown_level {
        position: absolute;
        content: '';
        top: -10px;
        left: 100%;
        margin-left: 0;
        background-color: #D4E1D2; }
        .navigation__dropdown_level:hover {
          display: block; } }
    .navigation__dropdown_level h3 {
      display: inline-block;
      width: 100%;
      font-size: 16px;
      color: #434343;
      white-space: nowrap; }
      @media (min-width: 1200px) {
        .navigation__dropdown_level h3 {
          padding: 8px 30px; }
          .navigation__dropdown_level h3:hover {
            background-color: #a4ba04; } }
      @media (min-width: 1350px) {
        .navigation__dropdown_level h3 {
          font-size: 16px; } }
  .navigation__dropdown-link {
    display: block;
    text-decoration: none;
    margin-bottom: 10px; }
    .navigation__dropdown-link:last-child {
      margin-bottom: 0; }
    @media (min-width: 1200px) {
      .navigation__dropdown-link {
        margin-bottom: 0; } }

.search {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom: 2px solid #e9efe8; }
  .search__input {
    width: 100%;
    height: 100%;
    padding: 15px 40px 15px 15px;
    border: none;
    outline: none; }
  .search__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto 0;
    width: 20px;
    height: 20px;
    background: url("../svg/icons/search.svg") center/contain no-repeat; }

.button-call {
  display: none;
  position: fixed;
  z-index: 2;
  right: -100px;
  transition: right .2s ease-in;
  width: 180px;
  height: 80px;
  padding: 15px 32px 15px 82px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #e9efe8;
  text-transform: lowercase; }
  .button-call:hover {
    right: 0;
    transition: right .2s ease-in; }
    .button-call:hover .button-call__text {
      opacity: 1;
      transition: opacity .2s ease-in; }
  .button-call::before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    margin: auto 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
  .button-call_mobile {
    display: none;
    position: fixed;
    z-index: 2;
    right: -100px;
    transition: right .2s ease-in;
    width: 180px;
    height: 80px;
    padding: 15px 32px 15px 82px;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #e9efe8;
    text-transform: lowercase; }
    .button-call_mobile::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      margin: auto 0;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat; }
    .button-call_mobile-wts {
      display: block;
      bottom: 80px;
      background-color: #51c85d;
      animation: hello 12s 1s infinite ease-in-out; }
      @media (min-width: 1200px) {
        .button-call_mobile-wts {
          display: none; } }
      .button-call_mobile-wts::before {
        width: 51px;
        height: 41px;
        left: 16px;
        background-image: url("../svg/button-call/watsapp1.svg"); }
    .button-call_mobile-feedback {
      display: block;
      bottom: 160px;
      background-color: #434343;
      animation: hello 12s .4s infinite ease-in-out; }
      @media (min-width: 1200px) {
        .button-call_mobile-feedback {
          display: none; } }
      .button-call_mobile-feedback::before {
        width: 38px;
        height: 39px;
        left: 19px;
        background-image: url("../svg/button-call/phone.svg"); }
      @media (max-width: 1199px) {
        .button-call_mobile-feedback {
          bottom: 0; } }
  .button-call_wts {
    text-decoration: none; }
  @media (min-width: 1200px) {
    .button-call {
      display: initial;
      position: fixed;
      z-index: 2;
      right: -100px;
      transition: right .2s ease-in;
      width: 180px;
      height: 80px;
      padding: 15px 32px 15px 82px;
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      color: #e9efe8;
      text-transform: lowercase; }
      .button-call:hover {
        right: 0;
        transition: right .2s ease-in; }
        .button-call:hover .button-call__text {
          opacity: 1;
          transition: opacity .2s ease-in; }
      .button-call::before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        margin: auto 0;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }
      .button-call_feedback {
        bottom: 160px;
        background-color: #434343;
        animation: hello 12s .4s infinite ease-in-out; }
        .button-call_feedback::before {
          width: 38px;
          height: 39px;
          left: 19px;
          background-image: url("../svg/button-call/phone.svg"); } }
    @media (min-width: 1200px) and (max-width: 1199px) {
      .button-call_feedback {
        bottom: 0; } }
  @media (min-width: 1200px) {
      .button-call_doctor-call {
        bottom: 80px;
        background-color: #f55e21;
        animation: hello 12s 1s infinite ease-in-out; }
        .button-call_doctor-call::before {
          width: 51px;
          height: 41px;
          left: 16px;
          background-image: url("../svg/button-call/car.svg"); }
      .button-call_appointment {
        bottom: 0;
        background-color: #679100;
        animation: hello 12s 1.6s infinite ease-in-out; }
        .button-call_appointment::before {
          width: 55px;
          height: 46px;
          left: 13px;
          background-image: url("../svg/button-call/pen.svg"); }
      .button-call__text {
        opacity: 0;
        transition: opacity .2s ease-in; }
      .button-call_wts {
        bottom: 240px;
        background-color: #51c85d;
        animation: hello 12s 1s infinite ease-in-out; }
        .button-call_wts::before {
          width: 51px;
          height: 41px;
          left: 16px;
          background-image: url("../svg/button-call/watsapp1.svg"); } }

@keyframes hello {
  0% {
    opacity: 1;
    transform: scale(1); }
  2.77% {
    opacity: 0.5;
    transform: scale(1.1); }
  5.55% {
    opacity: 1;
    transform: scale(1); } }

.navbar-first {
  padding: 15px 0;
  background-color: #e9efe8; }
  .navbar-first__content {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 768px) {
      .navbar-first__content {
        justify-content: flex-start; } }
  .navbar-first__logo {
    display: none; }
    @media (min-width: 768px) {
      .navbar-first__logo {
        display: initial;
        margin-right: auto; } }
    .navbar-first__logo--double {
      max-width: 250px;
      margin-left: 10px; }
  .navbar-first__search {
    margin-left: 20px; }
  .navbar-first__wrap {
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (min-width: 768px) {
      .navbar-first__wrap {
        align-items: flex-end;
        margin-left: 40px; } }
  @media (min-width: 768px) {
    .navbar-first__address {
      display: none; } }

.navbar-second {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #fff;
  border-bottom: 2px solid #e9efe8; }
  .navbar-second_glue {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0; }
  .navbar-second__content {
    display: flex;
    align-items: center;
    height: 100%; }
  .navbar-second__address {
    display: none; }
    @media (min-width: 768px) {
      .navbar-second__address {
        display: initial;
        margin-right: auto;
        font-size: 14px; } }
    @media (min-width: 1400px) {
      .navbar-second__address {
        font-size: 16px; } }
  @media (min-width: 768px) {
    .navbar-second__logo {
      display: none; } }
  .navbar-second__logo--double {
    max-width: 100px;
    width: 100%;
    object-fit: contain;
    height: auto; }
    @media (min-width: 430px) {
      .navbar-second__logo--double {
        max-width: 150px; } }
  .navbar-second__navigation {
    height: 100%;
    margin-left: auto; }

.other-btn {
  display: block;
  position: relative;
  width: 100%;
  min-height: 25px;
  padding: 0 0 0 32px;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #434343;
  text-transform: lowercase;
  text-align: left; }
  .other-btn::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
  @media (min-width: 1200px) {
    .other-btn {
      display: none; } }
  .other-btn_feedback {
    margin-bottom: 20px; }
    .other-btn_feedback::before {
      width: 23px;
      height: 20px;
      background-image: url("../svg/icons/feedback.svg"); }

.swiper-main {
  display: none; }
  @media (min-width: 768px) {
    .swiper-main {
      position: relative;
      width: 100%; } }
  @media (min-width: 1200px) {
    .swiper-main {
      display: block;
      margin-bottom: 20px; } }
  @media (min-width: 768px) and (max-height: 768px) {
    .swiper-main {
      display: block;
      margin-bottom: 80px; } }
  @media (min-width: 768px) {
    .swiper-main__slide {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: #e9efe8; } }
  @media (min-width: 1200px) {
    .swiper-main__slide {
      max-width: 1440px;
      height: 460px; } }
  @media (min-width: 768px) and (max-height: 768px) {
    .swiper-main__slide {
      max-width: 1150px;
      height: 420px; } }
  @media (min-width: 1200px) {
    .swiper-main__content {
      position: relative;
      height: 100%;
      padding: 80px 80px 130px 90px;
      display: flex;
      flex-direction: column; } }
  @media (min-width: 1200px) {
    .swiper-main__wrap {
      width: 100%;
      height: 100%;
      overflow-y: hidden; } }
  .swiper-main__title a {
    color: #434343;
    text-decoration: none;
    transition: color .1s; }
    .swiper-main__title a:hover {
      color: #a4ba04;
      transition: color .1s; }
  @media (min-width: 1200px) {
    .swiper-main__title {
      margin-top: 0;
      margin-bottom: 30px;
      font-family: 'Akrobat', Arial, sans-serif;
      font-size: 48px;
      font-weight: 600;
      text-transform: uppercase; } }
  @media (min-width: 1200px) {
    .swiper-main__description {
      margin-top: 0;
      margin-bottom: auto;
      font-size: 18px;
      font-weight: normal; } }
  @media (min-width: 1200px) {
    .swiper-main__btn {
      position: absolute;
      left: 90px;
      bottom: 2px;
      width: 215px;
      height: 65px;
      padding: 20px 50px;
      border: none;
      background-color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      color: #f55e21; }
      .swiper-main__btn:hover {
        border: none;
        background-color: #679100;
        color: white; } }
  @media (min-width: 768px) {
    .swiper-main__img {
      flex: 0 0 auto;
      height: 100%;
      background: url("../images/other/pattern.png") repeat; } }
  @media (min-width: 1200px) {
    .swiper-main__img {
      width: 720px; } }
  @media (min-width: 768px) and (max-height: 768px) {
    .swiper-main__img {
      width: 660px; } }
  @media (min-width: 1200px) {
    .swiper-main__img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; } }
  @media (min-width: 768px) {
    .swiper-main__tools {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%; } }
  @media (min-width: 1200px) {
    .swiper-main__tools {
      max-width: 1440px;
      height: 460px; } }
  @media (min-width: 768px) and (max-height: 768px) {
    .swiper-main__tools {
      max-width: 1150px;
      height: 420px; } }
  @media (min-width: 1200px) {
    .swiper-main__prev, .swiper-main__next {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      z-index: 1; }
      .swiper-main__prev:hover, .swiper-main__next:hover {
        opacity: 0.6; }
      .swiper-main__prev::after, .swiper-main__next::after {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        margin: auto 0; } }
  @media (min-width: 1200px) {
    .swiper-main__prev {
      left: 30px; }
      .swiper-main__prev::after {
        left: 0;
        width: 30px;
        height: 30px;
        border-top: 2px solid #434343;
        border-right: 2px solid #434343;
        transform: rotate(-135deg); } }
  @media (min-width: 1200px) {
    .swiper-main__next {
      right: 30px; }
      .swiper-main__next::after {
        right: 0;
        width: 30px;
        height: 30px;
        border-top: 2px solid #434343;
        border-right: 2px solid #434343;
        transform: rotate(45deg); } }
  @media (min-width: 768px) {
    .swiper-main__pagination {
      position: absolute;
      bottom: 60px;
      display: flex;
      padding-left: 10px;
      padding-right: 10px;
      background-color: rgba(255, 255, 255, 0.3); } }
  @media (min-width: 1200px) {
    .swiper-main__pagination {
      right: 110px; } }
  @media (min-width: 768px) and (max-height: 768px) {
    .swiper-main__pagination {
      right: 150px; } }
  @media (min-width: 1200px) {
    .swiper-main .swiper-pagination-bullet {
      width: 56px;
      height: 30px;
      margin: 0 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      background-color: transparent;
      opacity: 1; }
      .swiper-main .swiper-pagination-bullet::before {
        content: '';
        width: 56px;
        height: 2px;
        background-color: #fff; } }
  @media (min-width: 1200px) {
    .swiper-main .swiper-pagination-bullet-active::before {
      background-color: #f55e21; } }

.amenities-cards {
  margin-top: 30px;
  margin-bottom: 50px; }
  @media (min-width: 1200px) {
    .amenities-cards {
      margin-top: 0; } }
  .amenities-cards__title {
    margin-bottom: 40px;
    color: #679100; }
  .amenities-cards__wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px; }
    @media (min-width: 1500px) {
      .amenities-cards__wrap {
        justify-content: initial; } }
  .amenities-cards__hr {
    border-top: 2px solid #e9efe8; }
  .amenities-cards__btn {
    width: 195px;
    height: 70px;
    margin-top: -2px;
    font-size: 16px; }

.amenities-card {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 290px;
  height: 170px;
  margin: 0 15px 15px;
  padding: 40px 30px;
  background-blend-mode: lighten, multiply, normal;
  overflow: hidden;
  text-decoration: none; }
  @media (min-width: 576px) {
    .amenities-card {
      margin-bottom: 30px; } }
  @media (min-width: 360px) {
    .amenities-card {
      width: 337px;
      height: 198px; } }
  .amenities-card__title {
    font-size: 28px;
    text-transform: uppercase;
    color: #434343; }

.advantages {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  font-family: 'Akrobat', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0; }
  @media (min-width: 992px) {
    .advantages {
      margin-bottom: 70px; } }
  .advantages__block {
    width: 95px;
    background-color: #e9efe8; }
  .advantages__block-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1100px;
    flex: 1 1 auto;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #fff; }
    @media (min-width: 768px) {
      .advantages__block-content {
        padding-left: 30px;
        padding-right: 30px; } }
  .advantages__title {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: bold;
    text-align: center; }
    @media (min-width: 768px) {
      .advantages__title {
        font-size: 28px;
        margin-bottom: 28px; } }
    @media (min-width: 992px) {
      .advantages__title {
        font-size: 36px; } }
  .advantages__list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    @media (min-width: 1201px) {
      .advantages__list {
        flex-wrap: nowrap;
        justify-content: space-between; } }
    .advantages__list_column {
      align-items: flex-start; }
    .advantages__list_main-page {
      max-width: 830px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px; }
      @media (min-width: 576px) {
        .advantages__list_main-page {
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px 50px; } }
      .advantages__list_main-page .advantages__item {
        margin: 0;
        justify-content: initial; }
      .advantages__list_main-page .advantages__text {
        max-width: initial; }
  .advantages__item {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
    @media (min-width: 768px) {
      .advantages__item {
        width: initial;
        margin-left: 15px;
        margin-right: 15px;
        justify-content: initial; } }
    .advantages__item_column {
      flex-direction: column;
      align-items: flex-start; }
  .advantages__num {
    margin-right: 10px;
    font-size: 36px;
    color: #f55e21; }
    @media (min-width: 768px) {
      .advantages__num {
        font-size: 48px; } }
    @media (min-width: 992px) {
      .advantages__num {
        font-size: 80px; } }
    .advantages__num span {
      font-size: 14px; }
      @media (min-width: 768px) {
        .advantages__num span {
          font-size: 18px; } }
      @media (min-width: 992px) {
        .advantages__num span {
          font-size: 28px; } }
  .advantages__text {
    margin-bottom: 10px;
    max-width: 200px;
    font-size: 14px; }
    @media (min-width: 768px) {
      .advantages__text {
        font-size: 18px; } }
    @media (min-width: 992px) {
      .advantages__text {
        font-size: 28px; } }
    .advantages__text span {
      color: #f55e21; }

.specialists {
  margin-bottom: 70px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .specialists {
      margin-bottom: 30px; } }
  .specialists__wrapper {
    margin-bottom: -2px; }
    @media (min-width: 576px) {
      .specialists__wrapper {
        margin-bottom: 40px; } }
  .specialists__navigations {
    display: flex;
    border-top: 2px solid #e9efe8; }
  .specialists__arrow-prev, .specialists__arrow-next {
    width: 60px;
    height: 60px;
    flex: 0 0 auto;
    margin-top: -2px; }
  .specialists__arrow-next {
    margin-left: -2px; }
  .specialists__prev, .specialists__next {
    position: relative;
    width: 100%;
    height: 100%; }
    .specialists__prev::after, .specialists__next::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
  .specialists__prev::after {
    left: 5px;
    width: 12px;
    height: 12px;
    border-top: 2px solid #f55e21;
    border-right: 2px solid #f55e21;
    transform: rotate(-135deg); }
  @media (min-width: 992px) {
    .specialists__prev:hover::after {
      width: 12px;
      height: 12px;
      border-top: 2px solid white;
      border-right: 2px solid white;
      transform: rotate(-135deg); } }
  .specialists__next::after {
    right: 5px;
    width: 12px;
    height: 12px;
    border-top: 2px solid #f55e21;
    border-right: 2px solid #f55e21;
    transform: rotate(45deg); }
  @media (min-width: 992px) {
    .specialists__next:hover::after {
      width: 12px;
      height: 12px;
      border-top: 2px solid white;
      border-right: 2px solid white;
      transform: rotate(45deg); } }
  .specialists__btn {
    max-width: 240px;
    margin-top: -2px;
    margin-left: -2px;
    font-size: 14px; }
    @media (min-width: 576px) {
      .specialists__btn {
        font-size: 16px; } }

.doctor-card {
  display: block;
  width: 100%;
  border: 2px solid #e9efe8;
  text-decoration: none;
  color: #434343;
  word-break: break-word; }
  .doctor-card__img {
    height: 185px;
    display: flex;
    justify-content: center;
    background: url("../images/other/pattern.png") repeat; }
    .doctor-card__img img {
      width: 211px;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .doctor-card__description {
    display: flex;
    flex-direction: column;
    height: 175px;
    padding: 30px 15px 15px;
    background-color: #fff; }
    .doctor-card__description:hover {
      background-color: #e9efe8; }
  .doctor-card__name {
    display: inline-block;
    max-height: 110px;
    overflow-y: hidden;
    margin: 0 0 auto;
    font-size: 20px; }
  .doctor-card__specialty {
    display: inline-block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600; }

.about-us {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  border: 2px solid #e9efe8; }
  @media (min-width: 1500px) {
    .about-us {
      flex-direction: initial;
      height: 540px; } }
  .about-us__video {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    background: url(../images/other/pattern.png) 50%/contain repeat;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden; }
    @media (min-width: 1200px) {
      .about-us__video {
        padding-top: 36.25%; } }
    @media (min-width: 1500px) {
      .about-us__video {
        max-width: 580px;
        height: 100%; } }
    .about-us__video iframe {
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-width: 0;
      outline-width: 0; }
    .about-us__video video {
      width: 100%;
      height: calc(100vw - 30px);
      object-fit: contain;
      object-position: center; }
      @media (min-width: 768px) {
        .about-us__video video {
          width: initial;
          height: 400px; } }
      @media (min-width: 1500px) {
        .about-us__video video {
          width: 100%;
          height: 100%; } }
  .about-us__description {
    padding: 15px;
    border-bottom: 2px solid #e9efe8; }
    @media (min-width: 1500px) {
      .about-us__description {
        width: 480px;
        padding: 30px 45px 80px 60px;
        border-bottom: none;
        flex: 0 0 auto; } }
  .about-us__title {
    margin-top: 0;
    margin-bottom: 25px;
    color: #679100; }
    @media (min-width: 1500px) {
      .about-us__title {
        margin-bottom: 35px; } }
  .about-us__text {
    margin-top: 0;
    margin-bottom: 20px;
    letter-spacing: 0;
    line-height: 1.6; }
    .about-us__text:last-child {
      margin-bottom: 0; }
    .about-us__text p {
      margin: 0; }
    @media (min-width: 1500px) {
      .about-us__text_description {
        max-height: 305px;
        overflow-y: hidden; } }
  .about-us__btn {
    position: absolute;
    top: 100%;
    left: -2px;
    width: 230px; }
    @media (min-width: 1500px) {
      .about-us__btn {
        left: initial; } }
  .about-us__info {
    padding: 15px; }
    @media (min-width: 1500px) {
      .about-us__info {
        padding: 30px 45px 80px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center; } }
  .about-us__btn-map {
    width: 100%;
    font-size: 12px; }
    @media (min-width: 360px) {
      .about-us__btn-map {
        width: 280px;
        font-size: 14px; } }

.comments {
  margin-bottom: 50px; }
  .comments__title {
    color: #679100; }
  .comments__wrap {
    width: 100%;
    max-width: 1150px;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto; }
  .comments__swiper.active {
    display: block !important; }
  .comments__tabs {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -2px; }
  .comments__btn:first-child {
    margin-right: -2px; }
  @media (min-width: 768px) {
    .comments__btn {
      width: 230px; } }
  .comments__btn.active {
    background-color: #e9efe8; }
    .comments__btn.active:hover {
      background-color: #679100; }
  .comments__slide {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #e9efe8; }
    @media (min-width: 576px) {
      .comments__slide {
        flex-direction: initial;
        padding: 40px; } }
    @media (min-width: 768px) {
      .comments__slide {
        flex-direction: initial;
        padding: 40px 130px 40px 60px; } }
  .comments__info {
    width: 185px;
    margin-bottom: 20px;
    flex: 0 0 auto; }
    @media (min-width: 768px) {
      .comments__info {
        margin-bottom: 0; } }
  .comments__name {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 20px;
    color: #679100; }
  .comments__date {
    font-size: 14px;
    font-weight: 600; }
  .comments__text {
    height: 200px;
    overflow-y: hidden;
    line-height: 1.6; }
    .comments__text p {
      margin: 0; }
  .comments__navigation {
    display: flex;
    justify-content: flex-end;
    border-top: 2px solid #e9efe8; }
  .comments__arrow-prev, .comments__arrow-next {
    width: 60px;
    height: 60px;
    flex: 0 0 auto;
    margin-top: -2px;
    margin-left: -2px; }
  .comments__prev, .comments__next {
    position: relative;
    width: 100%;
    height: 100%; }
    .comments__prev::after, .comments__next::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
  .comments__prev::after {
    left: 5px;
    width: 12px;
    height: 12px;
    border-top: 2px solid #f55e21;
    border-right: 2px solid #f55e21;
    transform: rotate(-135deg); }
  .comments__prev:hover::after {
    width: 12px;
    height: 12px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(-135deg); }
  .comments__next::after {
    right: 5px;
    width: 12px;
    height: 12px;
    border-top: 2px solid #f55e21;
    border-right: 2px solid #f55e21;
    transform: rotate(45deg); }
  .comments__next:hover::after {
    width: 12px;
    height: 12px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg); }
  .comments__btn-all {
    max-width: 240px;
    margin-top: -2px;
    font-size: 14px; }
    @media (min-width: 576px) {
      .comments__btn-all {
        font-size: 16px; } }

.article-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid #e9efe8;
  text-decoration: none;
  color: #434343; }
  .article-card__img {
    width: 100%;
    display: flex;
    justify-content: center;
    background: url("../images/other/pattern.png") repeat; }
    .article-card__img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .article-card__description {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #fff; }
    @media (min-width: 768px) {
      .article-card__description {
        padding: 40px 40px 20px; } }
    .article-card__description_green {
      background-color: #e9efe8; }
  .article-card__date {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 600; }
  .article-card__title {
    margin-top: 0;
    margin-bottom: 20px;
    height: 92px;
    overflow-y: hidden; }
  .article-card__link {
    margin-top: auto;
    font-size: 18px;
    font-weight: 600;
    color: #f55e21; }

.article-horizontal-card {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  border: 2px solid #e9efe8;
  text-decoration: none;
  color: #434343; }
  @media (min-width: 992px) {
    .article-horizontal-card {
      flex-direction: initial;
      height: 250px; } }
  .article-horizontal-card:last-child {
    margin-bottom: 0; }
  .article-horizontal-card__img {
    width: 100%;
    height: 250px;
    flex: 0 0 auto;
    background: url("../images/other/pattern.png") repeat; }
    @media (min-width: 992px) {
      .article-horizontal-card__img {
        width: 425px;
        height: 100%; } }
    .article-horizontal-card__img img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center; }
  .article-horizontal-card__description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    padding: 40px 40px 20px 40px;
    background-color: #fff; }
    .article-horizontal-card__description:hover {
      background-color: #e9efe8; }
  .article-horizontal-card__title {
    max-height: 135px;
    overflow-y: hidden;
    margin: 0;
    font-size: 20px; }
  .article-horizontal-card__link {
    margin-top: 20px;
    color: #f55e21;
    font-weight: 600;
    font-size: 18px; }

.articles {
  width: 100%; }
  .articles__title {
    color: #679100; }
  .articles__content {
    width: 100%;
    display: grid;
    grid-template: 'article-1' 380px 'article-2' 380px 'article-3' 380px 'article-4' 380px 'form' 450px / 100%;
    grid-gap: 15px; }
    @media (min-width: 576px) {
      .articles__content {
        grid-template: 'article-1' 420px 'article-2' 420px 'article-3' 420px 'article-4' 420px 'form' 450px / 100%;
        grid-gap: 30px; } }
    @media (min-width: 768px) {
      .articles__content {
        grid-template: 'article-1 article-2' 450px 'article-3 article-4' 450px 'form      form     ' 525px / 1fr      1fr; } }
    @media (min-width: 1200px) {
      .articles__content {
        grid-template: 'article-1 article-1 article-2' 450px 'article-3 article-4 form     ' 525px / 1fr      1fr       2fr; } }
    .articles__content > .article-card:nth-child(1) {
      grid-area: article-1; }
    .articles__content > .article-card:nth-child(2) {
      grid-area: article-2; }
    .articles__content > .article-card:nth-child(3) {
      grid-area: article-3; }
    .articles__content > .article-card:nth-child(4) {
      grid-area: article-4; }
    .articles__content .ask-form {
      grid-area: form; }
  .articles__footer {
    margin-top: -2px;
    border-top: 2px solid #e9efe8; }
    @media (min-width: 992px) {
      .articles__footer {
        margin-top: 40px; } }
  .articles__btn {
    width: 100%;
    margin-top: -2px; }
    @media (min-width: 576px) {
      .articles__btn {
        width: 250px; } }

.ask-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px;
  border: 2px solid #e9efe8; }
  @media (min-width: 768px) {
    .ask-form {
      padding: 50px; } }
  .ask-form__title {
    margin-top: 0;
    margin-bottom: 20px;
    color: #f55e21;
    font-size: 24px; }
    @media (min-width: 576px) {
      .ask-form__title {
        font-size: 28px; } }
    @media (min-width: 768px) {
      .ask-form__title {
        margin-bottom: 40px; } }
  .ask-form__form {
    display: flex;
    flex-direction: column;
    margin-top: auto; }
  .ask-form__input, .ask-form__textarea {
    margin-bottom: 2px;
    padding: 20px;
    border: none;
    background-color: #e9efe8;
    font-size: 14px; }
    .ask-form__input::placeholder, .ask-form__textarea::placeholder {
      font-weight: 600;
      font-size: 14px;
      color: #434343; }
  .ask-form__textarea {
    resize: none;
    height: 120px; }
  .ask-form__btn {
    background-color: #e9efe8; }
    @media (min-width: 768px) {
      .ask-form__btn {
        width: 300px; } }
    .ask-form__btn:hover {
      background-color: #679100; }

.map {
  display: flex;
  flex-direction: column;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .map {
      margin-top: 20px; } }
  @media (min-width: 1200px) {
    .map {
      margin-top: 20px;
      flex-direction: initial; } }
  .map__title {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    color: #679100; }
    @media (min-width: 1200px) {
      .map__title {
        max-width: 540px; } }
  .map__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 15px;
    border: 2px solid #e9efe8;
    border-bottom: none;
    font-size: #434343;
    font-weight: 600; }
    @media (min-width: 1200px) {
      .map__description {
        border-right: none;
        padding: 40px; } }
  .map__text {
    width: 100%;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px; }
    @media (min-width: 1200px) {
      .map__text {
        max-width: 540px;
        font-size: 20px; } }
    .map__text p {
      margin: 0 0 20px; }
      .map__text p:last-child {
        margin-bottom: 0; }
  .map__img {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
    border: 2px solid #e9efe8;
    border-top: none; }
    @media (min-width: 1200px) {
      .map__img {
        width: 700px;
        border: none; } }

.contacts {
  display: flex;
  flex-direction: column;
  font-size: 18px; }
  .contacts__title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 600; }
    @media (min-width: 576px) {
      .contacts__title {
        font-size: 24px; } }
    @media (min-width: 1500px) {
      .contacts__title {
        margin-bottom: 40px; } }
  .contacts__link {
    text-decoration: none;
    color: #434343; }
    @media (min-width: 992px) {
      .contacts__link:hover {
        color: #a4ba04; } }
  .contacts__address {
    margin-bottom: 18px; }
  .contacts__email {
    margin-bottom: 12px; }
  .contacts__number {
    margin-bottom: 4px;
    font-size: 30px; }
    .contacts__number:last-child {
      margin-bottom: 0; }

.social-links {
  display: flex; }
  .social-links__item {
    display: block;
    width: 35px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
    .social-links__item_youtube {
      background-image: url("../svg/socialWebNew/youtube.svg"); }
    .social-links__item_vk {
      background-image: url("../svg/socialWebNew/vk.svg"); }
    .social-links__item_fb {
      background-image: url("../svg/socialWebNew/fb.svg"); }
    .social-links__item_vb {
      background-image: url("../svg/socialWebNew/vb.svg"); }
    .social-links__item_wa {
      background-image: url("../svg/socialWebNew/wa.svg"); }
    .social-links__item_ok {
      background-image: url("../svg/socialWebNew/ok.svg"); }
    .social-links__item_inst {
      background-image: url("../svg/socialWebNew/inst.svg"); }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: z-index .1s, opacity .1s;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center; }
  .modal_open {
    opacity: 1;
    z-index: 9999;
    transition: z-index .1s, opacity .1s; }
  .modal__window {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px;
    background: #FFFFFF;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity .2s, transform .2s; }
    @media (min-width: 576px) {
      .modal__window {
        width: 100%;
        height: initial;
        padding: 30px; } }
    .modal__window_call-feedback {
      max-width: 590px; }
    .modal__window_doctor-call {
      max-width: 590px; }
    .modal__window_appointment {
      max-width: 950px; }
    .modal__window_call-analyzes {
      max-width: 590px; }
    .modal__window_thanks-feedback, .modal__window_thanks-appointment {
      width: 590px;
      min-height: 370px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .modal__window_thanks-feedback .modal__title, .modal__window_thanks-appointment .modal__title {
        max-width: 320px;
        text-align: center; }
    .modal__window_open {
      opacity: 1;
      transform: translateY(0);
      transition: opacity .2s, transform .2s; }
  .modal__btn-close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
    padding: 0;
    border: 0;
    background: url("../svg/icons/close.svg") center/contain no-repeat; }
    .modal__btn-close:focus {
      outline: 0;
      box-shadow: none; }
    .modal__btn-close:active {
      opacity: .6; }
    @media (min-width: 576px) {
      .modal__btn-close {
        top: 45px;
        right: 45px; } }
  .modal__title {
    margin-top: 0;
    margin-bottom: 20px;
    padding-right: 30px; }
    @media (min-width: 576px) {
      .modal__title {
        margin-bottom: 50px;
        padding-right: 40px; } }
  .modal__body {
    width: 100%; }
  .modal__areas {
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .modal__areas_appointment {
        width: 55%; } }
  .modal__area {
    margin-bottom: 15px; }
    @media (min-width: 576px) {
      .modal__area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -2px;
        margin-bottom: 0; }
        .modal__area:first-child {
          margin-top: 0; } }
    .modal__area_textarea {
      margin-bottom: 50px; }
      @media (min-width: 992px) {
        .modal__area_textarea .modal__textarea {
          width: calc(100% - 148px); } }
  .modal__label {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 16px;
    color: #434343;
    font-weight: 600; }
    @media (min-width: 576px) {
      .modal__label {
        max-width: 25%;
        margin-bottom: 0;
        font-size: 18px; } }
    .modal__label_required {
      position: relative; }
      .modal__label_required::before {
        position: absolute;
        content: '*';
        top: 0;
        right: -10px;
        color: red; }
  .modal__input, .modal__select, .modal__textarea {
    width: 100%;
    padding: 20px 25px;
    border: 2px solid #e9efe8;
    font-weight: 600;
    font-size: 18px;
    color: #434343;
    background-color: #fff;
    appearance: none; }
    .modal__input::placeholder, .modal__select::placeholder, .modal__textarea::placeholder {
      font-weight: 500;
      color: lightgray; }
    @media (min-width: 576px) {
      .modal__input, .modal__select, .modal__textarea {
        width: 70%; } }
  .modal__textarea {
    resize: none;
    height: 100px; }
  .modal__checkbox {
    margin-bottom: 15px; }
    .modal__checkbox input {
      display: none; }
      .modal__checkbox input:checked + .modal__checkbox-fake::before {
        opacity: 1;
        transition: opacity .2s ease-in; }
  .modal__label-checkbox {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-left: 30px; }
  .modal__checkbox-fake {
    position: absolute;
    top: 4px;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #e9efe8; }
    .modal__checkbox-fake::before {
      opacity: 0;
      transition: opacity .2s ease-in;
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 3px;
      margin: auto 0;
      width: 10px;
      height: 10px;
      background-color: #f55e21; }
  .modal__checkbox-text {
    font-weight: 400; }
    .modal__checkbox-text a {
      color: inherit;
      text-decoration: none;
      font-weight: 600; }
  .modal__required {
    position: relative;
    display: flex;
    padding-left: 30px; }
    .modal__required::before {
      position: absolute;
      content: '*';
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;
      color: red; }
  .modal__btn {
    margin-top: 45px;
    font-size: 16px; }
  .modal__calendar {
    margin-top: 20px; }
    @media (min-width: 992px) {
      .modal__calendar {
        width: calc(45% - 30px);
        margin-top: 0; } }
    .modal__calendar .ui-datepicker {
      padding: 0; }
    .modal__calendar .ui-widget-header {
      font-weight: 600;
      font-size: 14px;
      color: #434343;
      border: none;
      border-bottom: 2px solid #e9efe8;
      border-radius: 0;
      background: #fff; }
    .modal__calendar .ui-state-hover {
      background-color: transparent;
      border: 0;
      opacity: 1;
      transform: scale(1.05); }
    .modal__calendar .ui-widget-header .ui-icon {
      background-image: url("../images/ui-icons_F55E21_256x240.png"); }
    .modal__calendar .ui-icon-circle-triangle-w {
      background-position: -100px 0px; }
    .modal__calendar .ui-icon-circle-triangle-e {
      background-position: -30px 0px; }
    .modal__calendar .ui-datepicker-calendar thead tr {
      background-color: #e9efe8; }
    .modal__calendar .ui-datepicker-calendar tbody tr td:first-child {
      border-left: 0; }
    .modal__calendar .ui-datepicker-calendar tbody tr td:last-child {
      border-right: 0; }
    .modal__calendar .ui-datepicker-week-end {
      color: #f55e21; }
    .modal__calendar .ui-datepicker td {
      margin: 0;
      padding: 0;
      border: 2px solid #e9efe8; }
      .modal__calendar .ui-datepicker td:first-child {
        border-top: 2px solid #e9efe8;
        border-left: 2px solid #e9efe8; }
    .modal__calendar .ui-state-default {
      padding: 7px 10px;
      border: 0;
      background-color: #fff;
      text-align: center;
      font-weight: 600;
      font-size: 12px; }
    .modal__calendar .ui-widget.ui-widget-content {
      border: 2px solid #e9efe8; }
    .modal__calendar .datepicker {
      width: 100%; }
    .modal__calendar .ui-datepicker {
      width: 100%; }
    .modal__calendar .ui-datepicker table {
      margin-bottom: -2px; }
    .modal__calendar .ui-state-active {
      background-color: #679100;
      color: #fff; }
  .modal__wrap-appointment {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .modal__wrap-appointment {
        flex-direction: initial;
        justify-content: space-between;
        margin-bottom: 50px; } }
    .modal__wrap-appointment .modal__areas {
      margin-bottom: 0; }
  .modal__footer {
    display: flex;
    flex-direction: column; }
    @media (min-width: 992px) {
      .modal__footer {
        flex-direction: initial;
        align-items: center; } }
    @media (min-width: 992px) {
      .modal__footer .modal__btn {
        max-width: 350px;
        margin-top: 0; } }
  @media (min-width: 992px) {
    .modal__checkbox-wrap {
      margin-right: 30px; } }
  .modal__text {
    margin: 0;
    text-align: center;
    font-weight: 600; }

.error-404 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .error-404__title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 150px; }
    @media (min-width: 576px) {
      .error-404__title {
        font-size: 270px; } }
  .error-404__text {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 18px; }
    @media (min-width: 576px) {
      .error-404__text {
        font-size: 30px; } }
  .error-404__btn {
    max-width: 275px;
    margin-top: 20px;
    transform: scale(0.8);
    font-size: 16px; }
    @media (min-width: 576px) {
      .error-404__btn {
        margin-top: 60px;
        transform: scale(1); } }

.breadcrumbs {
  margin-bottom: 15px; }
  @media (min-width: 1024px) {
    .breadcrumbs {
      margin-bottom: 20px; } }
  .breadcrumbs a, .breadcrumbs span {
    color: #C2C2C2;
    text-decoration: none;
    font-size: 14px; }
  .breadcrumbs span {
    opacity: .5;
    margin-right: 5px;
    margin-left: 5px; }

.article_mb {
  margin-bottom: 20px; }
  @media (min-width: 576px) {
    .article_mb {
      margin-bottom: 40px; } }

.article__title {
  margin-top: 0;
  margin-bottom: 20px; }

.article__hr {
  margin-bottom: 40px;
  border: none;
  height: 2px;
  background-color: #e9efe8; }

.article__pic {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  background: url("../images/other/pattern.png") repeat; }
  @media (min-width: 992px) {
    .article__pic {
      margin-bottom: 80px; } }
  .article__pic::before {
    position: absolute;
    content: '';
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: calc(100% - 40px);
    height: 100%;
    border: 2px solid #e9efe8; }
    @media (min-width: 992px) {
      .article__pic::before {
        top: 40px;
        width: calc(100% - 80px); } }
  .article__pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

@media (min-width: 1200px) {
  .article__text.margin-slider {
    margin-top: 650px; } }

.article__text p {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5; }
  .article__text p:last-child {
    margin-bottom: 0; }

.article-swiper-container {
  display: none; }
  @media (min-width: 1200px) {
    .article-swiper-container {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 600px;
      background: url("../images/other/pattern.png") repeat; } }

.article-slider {
  position: relative;
  width: 100%;
  max-width: 1440px; }
  .article-slider .swiper-wrapper {
    max-width: 100%; }
    .article-slider .swiper-wrapper .swiper-slide {
      position: relative; }
      .article-slider .swiper-wrapper .swiper-slide img {
        width: 100%;
        height: 600px;
        object-fit: cover;
        object-position: center; }

.directions {
  padding: 15px;
  border: 2px solid #e9efe8; }
  @media (min-width: 576px) {
    .directions {
      margin-left: -60px;
      padding: 60px; } }
  .directions__title {
    margin-top: 0;
    margin-bottom: 20px; }
  .directions__block {
    margin-bottom: 40px; }
    .directions__block:last-child {
      margin-bottom: 0; }
  .directions__subtitle {
    font-size: 20px; }
  .directions__list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 20px; }
  .directions__item {
    position: relative;
    margin-bottom: 15px;
    padding-left: 20px; }
    .directions__item:last-child {
      margin-bottom: 0; }
    .directions__item::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 0;
      width: 10px;
      height: 10px;
      background-color: #e9efe8;
      border-radius: 100%; }
  .directions__link {
    font-size: 16px;
    color: #434343;
    text-decoration: none; }
    @media (min-width: 576px) {
      .directions__link {
        font-size: 18px; } }

.recommendations__hr {
  margin-bottom: 40px; }

.recommendations__filter-panel {
  margin-bottom: 40px; }

.filter-panel {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #e9efe8; }
  @media (min-width: 992px) {
    .filter-panel {
      flex-direction: initial;
      height: 50px; } }
  .filter-panel_mb {
    margin-bottom: 25px; }
    @media (min-width: 1024px) {
      .filter-panel_mb {
        margin-bottom: 60px; } }
  .filter-panel__select {
    position: relative;
    width: 100%;
    height: 50px;
    margin-bottom: -2px;
    background-color: #fff; }
    @media (min-width: 992px) {
      .filter-panel__select {
        width: initial;
        height: initial;
        margin-left: -2px; } }
    .filter-panel__select select {
      position: relative;
      width: 100%;
      height: 100%;
      padding-left: 25px;
      padding-right: 50px;
      background-color: transparent;
      border: 2px solid #e9efe8;
      font-weight: 600;
      font-size: 18px;
      color: #434343;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
      @media (min-width: 992px) {
        .filter-panel__select select {
          width: initial; } }
      .filter-panel__select select:focus {
        z-index: 1; }
    .filter-panel__select:first-child {
      margin-left: 0; }
    .filter-panel__select::before {
      position: absolute;
      content: '';
      top: -4px;
      bottom: 0;
      right: 15px;
      margin: auto 0;
      z-index: 1;
      width: 12px;
      height: 12px;
      border-top: 2px solid #f55e21;
      border-right: 2px solid #f55e21;
      transform: rotate(135deg); }
    .filter-panel__select::placeholder {
      font-weight: 500;
      color: lightgray; }
  .filter-panel__search {
    position: relative;
    height: 50px;
    margin-bottom: -2px; }
    @media (min-width: 992px) {
      .filter-panel__search {
        width: 100%; } }
    @media (min-width: 992px) {
      .filter-panel__search_first {
        height: initial; } }
  .filter-panel__input-search {
    width: 100%;
    height: 100%;
    padding-left: 50px;
    padding-right: 25px;
    border: 2px solid #e9efe8;
    font-weight: 600;
    font-size: 18px;
    color: #434343;
    text-align: left;
    background-color: #fff;
    appearance: none; }
    @media (min-width: 992px) {
      .filter-panel__input-search {
        border: none;
        border-bottom: 2px solid #e9efe8; } }
    .filter-panel__input-search:focus {
      position: relative;
      z-index: 1; }
    .filter-panel__input-search::placeholder {
      font-weight: 500;
      color: lightgray; }
  .filter-panel__btn-search {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    margin: auto 0;
    z-index: 1;
    width: 20px;
    height: 20px;
    background: url("../svg/icons/search.svg") center/contain no-repeat; }

.contacts-page__text p {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: justify; }
  .contacts-page__text p:last-child {
    margin-bottom: 50px; }

.contacts-page .map {
  border-bottom: 2px solid #e9efe8;
  margin-bottom: 20px; }
  .contacts-page .map__img {
    height: 400px; }

.docs {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  width: 100%;
  min-height: 100px;
  margin-top: 20px;
  margin-bottom: 40px; }
  .docs:first-child {
    margin-top: 0; }
  @media (min-width: 768px) {
    .docs {
      grid-template-columns: 1fr 1fr; } }
  .docs__pic {
    width: 85px;
    height: 100px;
    margin-right: 30px;
    flex: 0 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  .docs__text {
    font-size: 18px; }
  .docs__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #434343; }

.profile {
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .profile {
      margin-bottom: 70px; } }
  .profile__wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px; }
    @media (min-width: 768px) {
      .profile__wrap {
        flex-direction: initial; } }
  .profile__info {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-right: 30px; }
  .profile__title {
    margin-top: 0;
    margin-bottom: 35px; }
  .profile__profession {
    margin-top: 0;
    margin-bottom: 15px; }
  .profile__experience {
    margin-top: 0;
    margin-bottom: 15px; }
  .profile__text {
    margin-top: 0;
    margin-bottom: 30px; }
    @media (min-width: 576px) {
      .profile__text {
        margin-bottom: 50px; } }
  .profile__btn {
    margin-top: auto;
    max-width: 300px; }
  .profile__pic {
    order: -1;
    width: 100%;
    height: 175px;
    margin-bottom: 15px;
    flex: 0 0 auto;
    background: url("../images/other/pattern.png"); }
    @media (min-width: 576px) {
      .profile__pic {
        width: 450px;
        height: 350px; } }
    @media (min-width: 768px) {
      .profile__pic {
        order: initial;
        margin-bottom: 0; } }
    .profile__pic img {
      width: 300px;
      height: 100%;
      object-fit: contain;
      object-position: center; }
      @media (min-width: 576px) {
        .profile__pic img {
          width: 100%; } }

.price {
  width: 100%;
  margin-bottom: 30px;
  font-size: 18px; }
  @media (min-width: 1024px) {
    .price {
      margin-bottom: 60px; } }
  .price__row {
    position: relative;
    display: flex;
    margin-top: -2px;
    padding: 15px 25px;
    background-color: white;
    border: 2px solid #e9efe8;
    color: #434343;
    text-decoration: none; }
    .price__row:first-child {
      margin-top: 0; }
    @media (min-width: 768px) {
      .price__row {
        padding: 15px 40px; } }
  .price__service {
    margin: 0 15px 0 0;
    font-size: 14px; }
    @media (min-width: 576px) {
      .price__service {
        margin-right: 30px;
        font-size: 18px; } }
  .price__cost {
    flex: 0 0 auto;
    min-width: 70px;
    margin: 0 0 0 auto;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #FF6230; }
    @media (min-width: 576px) {
      .price__cost {
        min-width: 100px;
        font-size: 20px; } }
  .price__stock {
    margin: 0 auto;
    font-size: 14px;
    font-weight: 600;
    color: #f55e21; }
    @media (min-width: 576px) {
      .price__stock {
        font-size: 20px; } }

.feedback__hr {
  margin-bottom: 40px; }

.feedback__filter-panel {
  margin-bottom: 40px; }

.feedback__item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #e9efe8; }
  .feedback__item:last-child {
    margin-bottom: 0;
    border-bottom: none; }

.feedback__name {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 20px;
  color: #679100;
  font-weight: 600; }

.feedback__date {
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 14px; }

.feedback__text p {
  margin: 0;
  line-height: 1.4;
  font-size: 14px; }
  @media (min-width: 576px) {
    .feedback__text p {
      font-size: 16px; } }

.different {
  font-family: 'Akrobat', sans-serif;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: #434343; }
  .different_mb {
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .different_mb {
        margin-bottom: 55px; } }
  .different__title {
    margin-bottom: 25px;
    font-size: 24px;
    text-align: center;
    font-weight: bold; }
    @media (min-width: 992px) {
      .different__title {
        font-size: 28px; } }
    @media (min-width: 1200px) {
      .different__title {
        font-size: 36px; } }
  .different__head {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 2px solid #e9efe8;
    border-bottom: 2px solid #e9efe8; }
    @media (min-width: 576px) {
      .different__head {
        border-top: 4px solid #e9efe8;
        border-bottom: 4px solid #e9efe8; } }
    @media (min-width: 768px) {
      .different__head {
        margin-bottom: 25px;
        flex-wrap: initial; } }
  .different__title-col {
    padding-right: 20px;
    display: flex;
    justify-content: center;
    font-size: 20px; }
    .different__title-col:last-child {
      margin-right: 0; }
    @media (min-width: 992px) {
      .different__title-col {
        font-size: 24px; } }
    @media (min-width: 1200px) {
      .different__title-col {
        font-size: 36px; } }
    .different__title-col_other {
      width: 100%;
      margin-bottom: 20px;
      padding: 0 20px 0; }
      @media (min-width: 768px) {
        .different__title-col_other {
          width: calc(50% - 30px);
          margin-bottom: 0;
          padding-left: 80px; } }
    .different__title-col_we {
      width: 100%;
      padding: 0 20px 0;
      justify-content: center;
      color: #679100; }
      @media (min-width: 768px) {
        .different__title-col_we {
          width: calc(50% + 30px);
          padding-left: 47px;
          justify-content: flex-start; } }
  .different__body {
    display: flex;
    flex-wrap: wrap; }
  .different__other, .different__we {
    height: auto;
    overflow: hidden;
    margin-bottom: 5px;
    padding: 20px;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 1.2; }
    .different__other:nth-last-child(-n + 2), .different__we:nth-last-child(-n + 2) {
      margin-bottom: 0; }
    @media (min-width: 768px) {
      .different__other, .different__we {
        height: 140px; } }
    @media (min-width: 992px) {
      .different__other, .different__we {
        height: 160px;
        font-size: 24px; } }
    @media (min-width: 1200px) {
      .different__other, .different__we {
        height: 190px;
        font-size: 28px; } }
  .different__other {
    width: 100%;
    padding: 30px 20px 30px 60px;
    background-color: #fff; }
    @media (min-width: 768px) {
      .different__other {
        width: calc(50% - 30px);
        padding-left: 55px;
        padding-right: 20px; } }
    @media (min-width: 992px) {
      .different__other {
        padding: 20px; } }
  .different__we {
    width: 100%;
    padding: 30px 20px 30px 60px;
    background-color: #e9efe8; }
    @media (min-width: 768px) {
      .different__we {
        width: calc(50% + 30px);
        padding: 20px 20px 20px 60px; } }
    @media (min-width: 992px) {
      .different__we {
        padding-left: 100px;
        padding-right: 80px; } }
  .different__icon {
    display: none; }
    @media (min-width: 768px) {
      .different__icon {
        display: initial;
        width: 60px;
        height: 50px;
        margin-right: 40px;
        flex: 0 0 auto;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; } }
    @media (min-width: 992px) {
      .different__icon {
        width: 80px;
        height: 67px;
        margin-right: 80px; } }
    @media (min-width: 1200px) {
      .different__icon {
        width: 130px;
        height: 110px;
        margin-right: 95px; } }
  .different__text {
    position: relative; }
    .different__text_minus::before {
      position: absolute;
      content: '';
      top: 5px;
      left: -25px;
      width: 15px;
      height: 15px;
      background: url("../svg/different/close.svg") center/contain no-repeat; }
      @media (min-width: 1200px) {
        .different__text_minus::before {
          width: 21px;
          height: 22px;
          left: -35px; } }
    .different__text_plus::before {
      position: absolute;
      content: '';
      top: 8px;
      left: -30px;
      width: 20px;
      height: 12px;
      background: url("../svg/different/checked.svg") center/contain no-repeat; }
      @media (min-width: 768px) {
        .different__text_plus::before {
          width: 28px;
          height: 17px;
          left: -40px; } }
      @media (min-width: 1200px) {
        .different__text_plus::before {
          width: 36px;
          height: 23px;
          left: -53px; } }
  .different__footer {
    border-bottom: 2px solid #e9efe8; }
    @media (min-width: 576px) {
      .different__footer {
        border-bottom: 4px solid #e9efe8; } }
  .different__footer-text {
    width: 100%;
    padding: 30px;
    font-size: 20px;
    color: #f55e21; }
    @media (min-width: 768px) {
      .different__footer-text {
        width: calc(50% + 30px);
        margin-left: calc(50% - 30px);
        padding: 40px;
        font-size: 24px; } }
    @media (min-width: 1200px) {
      .different__footer-text {
        font-size: 36px; } }

.our-specialists__hr {
  margin-bottom: 40px; }

.our-specialists__filter-panel {
  margin-bottom: 40px; }

.our-specialists__cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px; }
  @media (min-width: 576px) {
    .our-specialists__cards {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 768px) {
    .our-specialists__cards {
      grid-template-columns: repeat(3, 1fr); } }
  @media (min-width: 992px) {
    .our-specialists__cards {
      grid-template-columns: repeat(4, 1fr); } }
  @media (min-width: 1300px) {
    .our-specialists__cards {
      grid-template-columns: repeat(5, 1fr); } }
  @media (min-width: 1500px) {
    .our-specialists__cards {
      grid-template-columns: repeat(6, 1fr); } }

.amenities__hr {
  margin-bottom: 20px; }

.amenities__description {
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .amenities__description {
      margin-bottom: 60px; } }

.amenities__spoilers {
  margin-bottom: 30px; }
  @media (min-width: 576px) {
    .amenities__spoilers {
      margin-bottom: 60px; } }

.spoiler {
  margin-bottom: 2px;
  border: 2px solid #e9efe8; }
  .spoiler_mb-2 {
    margin-bottom: -2px; }
  .spoiler.open .spoiler__indicator::after {
    opacity: 0;
    transition: opacity .2s ease-in; }
  .spoiler.open .spoiler__body {
    transition: height .2s ease-in; }
  .spoiler__header {
    position: relative;
    cursor: pointer;
    padding: 10px;
    padding-right: 40px;
    background-color: #e9efe8; }
    .spoiler__header:hover {
      background-color: #d4e0d2; }
    @media (min-width: 576px) {
      .spoiler__header {
        padding: 20px 40px; } }
  .spoiler__indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto 0;
    width: 20px;
    height: 20px; }
    .spoiler__indicator::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 2px;
      background-color: #f55e21; }
    .spoiler__indicator::after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      margin: auto;
      width: 2px;
      height: 100%;
      background-color: #f55e21;
      opacity: 1;
      transition: opacity .2s ease-in; }
  .spoiler__section-name {
    margin: 0;
    font-size: 16px;
    font-weight: 600; }
    @media (min-width: 576px) {
      .spoiler__section-name {
        font-size: 20px; } }
  .spoiler__body {
    overflow-y: hidden;
    transition: height .2s ease-in; }
  .spoiler__row {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-bottom: 2px solid #e9efe8;
    text-decoration: none;
    color: #434343; }
    .spoiler__row:last-child {
      border-bottom: none; }
    .spoiler__row:not(.spoiler__row_no-hover):hover {
      background-color: #679100;
      color: white; }
      .spoiler__row:not(.spoiler__row_no-hover):hover .spoiler__price {
        color: white; }
    @media (min-width: 576px) {
      .spoiler__row {
        padding: 20px 40px; } }
    .spoiler__row_more {
      padding: 10px;
      background-color: #e9efe8; }
      .spoiler__row_more:hover {
        background-color: #e9efe8; }
      @media (min-width: 576px) {
        .spoiler__row_more {
          padding: 15px 40px; } }
    .spoiler__row_full {
      align-items: flex-end;
      justify-content: flex-end;
      background-color: rgba(233, 239, 232, 0.5); }
      .spoiler__row_full b {
        font-size: 18px; }
  .spoiler__more {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #f55e21; }
    @media (min-width: 576px) {
      .spoiler__more {
        font-size: 14px; } }
  .spoiler__amenities-wrap {
    margin: 0 15px 0 0; }
    @media (min-width: 576px) {
      .spoiler__amenities-wrap {
        margin-right: 30px; } }
  .spoiler__amenities-descr {
    margin: 0;
    font-size: 14px;
    line-height: 1.6; }
    @media (min-width: 576px) {
      .spoiler__amenities-descr {
        font-size: 18px; } }
  .spoiler__text {
    font-size: 14px;
    margin-right: 15px; }
    .spoiler__text b {
      font-size: 18px; }
  .spoiler__amenities {
    margin: 0 15px 0 0;
    font-size: 14px; }
    @media (min-width: 576px) {
      .spoiler__amenities {
        margin-right: 30px;
        font-size: 18px; } }
    .spoiler__amenities_stock {
      display: flex;
      align-items: center; }
      .spoiler__amenities_stock::after {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        content: 'Акция %';
        margin-left: 15px;
        padding-right: 5px;
        width: 76px;
        height: 25px;
        background-color: #f55e21;
        font-size: 12px;
        text-transform: uppercase;
        color: white;
        clip-path: polygon(6px 0%, 100% 0%, 100% 100%, 6px 100%, 0% 50%); }
    .spoiler__amenities_bold {
      margin-bottom: 5px;
      font-weight: 600; }
    .spoiler__amenities_home {
      display: flex;
      align-items: center; }
      .spoiler__amenities_home::after {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        content: '';
        margin-left: 15px;
        padding-right: 5px;
        width: 39px;
        height: 25px;
        background: #679100 url("../svg/icons/home.svg") center/35% no-repeat;
        clip-path: polygon(6px 0%, 100% 0%, 100% 100%, 6px 100%, 0% 50%); }
  .spoiler__term {
    flex: 0 0 auto;
    min-width: 70px;
    margin-left: auto;
    margin-right: 80px;
    font-weight: 600;
    font-size: 14px;
    text-align: right; }
    @media (min-width: 576px) {
      .spoiler__term {
        min-width: 100px;
        font-size: 20px; } }
    @media (min-width: 768px) {
      .spoiler__term {
        font-size: 20px; } }
  .spoiler__price {
    align-self: flex-start;
    flex: 0 0 auto;
    min-width: 70px;
    font-family: 'Akrobat', Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    text-align: right;
    color: #f55e21; }
    @media (min-width: 576px) {
      .spoiler__price {
        min-width: 100px; } }
    @media (min-width: 768px) {
      .spoiler__price {
        font-size: 36px; } }
    .spoiler__price_ml-auto {
      margin-left: auto; }
    .spoiler__price_green {
      margin-left: 70px;
      color: #679100; }
      @media (min-width: 768px) {
        .spoiler__price_green {
          font-size: 24px; } }

.programme {
  margin-bottom: 30px; }
  @media (min-width: 576px) {
    .programme {
      margin-bottom: 60px; } }
  .programme__hr {
    margin-bottom: 20px; }
    @media (min-width: 576px) {
      .programme__hr {
        margin-bottom: 40px; } }
  .programme__description {
    margin-bottom: 30px; }
    @media (min-width: 576px) {
      .programme__description {
        margin-bottom: 60px; } }

.search-page__hr {
  margin-bottom: 20px; }

.search-page__filter-panel {
  margin-bottom: 20px; }

.search-page__result-text {
  margin-top: 0;
  margin-bottom: 20px; }

.result {
  margin-bottom: 30px;
  padding-bottom: 25px;
  border-bottom: 2px solid #e9efe8; }
  @media (min-width: 576px) {
    .result {
      margin-bottom: 60px;
      padding-bottom: 50px; } }
  .result:last-child {
    border-bottom: none; }
  .result__link {
    text-decoration: none;
    color: #434343; }
  .result__name {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 600; }
  .result__text {
    margin-bottom: 25px; }
    @media (min-width: 576px) {
      .result__text {
        margin-bottom: 50px; } }
    .result__text p {
      margin: 0; }
  .result__words {
    color: #f55e21; }
  .result__tag {
    font-weight: 600;
    font-size: 14px;
    color: #679100;
    text-decoration: none; }

.doctor-call {
  width: 100%;
  padding: 20px;
  border: 2px solid #e9efe8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .doctor-call {
      flex-direction: initial;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 30px 45px; } }
  @media (min-width: 1200px) {
    .doctor-call {
      justify-content: initial; } }
  .doctor-call_mb {
    margin-bottom: 30px; }
    @media (min-width: 1200px) {
      .doctor-call_mb {
        margin-bottom: 60px; } }
  .doctor-call__title {
    width: 100%;
    margin-right: auto;
    margin-bottom: 20px;
    font-size: 24px; }
    @media (min-width: 576px) {
      .doctor-call__title {
        font-size: 36px; } }
    @media (min-width: 1200px) {
      .doctor-call__title {
        width: initial;
        margin-bottom: 0; } }
  .doctor-call__text {
    width: 100%;
    margin-right: auto;
    margin-bottom: 20px;
    font-family: 'Akrobat', sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.1;
    text-transform: uppercase;
    color: #434343;
    text-align: center; }
    @media (min-width: 576px) {
      .doctor-call__text {
        font-size: 36px; } }
    @media (min-width: 1200px) {
      .doctor-call__text {
        width: initial;
        margin-bottom: 0;
        text-align: initial; } }
  .doctor-call__alert {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 16px;
    line-height: 1.2;
    color: #f55e21;
    text-align: center; }
    @media (min-width: 768px) {
      .doctor-call__alert {
        width: initial;
        margin-left: 30px;
        margin-right: 20px;
        margin-bottom: 0;
        text-align: initial; } }
    @media (min-width: 1200px) {
      .doctor-call__alert {
        font-size: 20px;
        margin-right: 50px; } }
  .doctor-call__btn {
    width: 100%; }
    @media (min-width: 768px) {
      .doctor-call__btn {
        width: 320px; } }
    @media (min-width: 768px) {
      .doctor-call__btn_little {
        width: 240px; } }

.doctor-call__title--fz-28 {
  font-size: 28px; }

.doctor-call--mb20 {
  margin-bottom: 20px; }

.stock__hr {
  margin-bottom: 40px; }

.stock__wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .stock__wrap {
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      margin-bottom: 60px; } }

.banner {
  position: relative;
  display: block;
  height: 200px;
  margin-bottom: 80px;
  text-decoration: none;
  color: #434343; }
  @media (min-width: 768px) {
    .banner {
      height: 400px; }
      .banner:hover img {
        transform: scale(1.2);
        transition: transform .2s ease-in; } }
  .banner::before {
    position: absolute;
    content: '';
    top: 20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 1;
    width: calc(100% - 40px);
    height: 100%;
    border: 2px solid #e9efe8; }
    @media (min-width: 768px) {
      .banner::before {
        width: calc(100% - 80px);
        top: 40px; } }
  .banner::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }
  .banner__pic {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../images/other/pattern.png"); }
    .banner__pic img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      transition: transform .2s ease-in; }
  .banner__info {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 80px);
    height: calc(100% - 60px);
    color: white; }
    @media (min-width: 768px) {
      .banner__info {
        width: calc(100% - 160px);
        height: calc(100% - 120px);
        top: 80px; } }
  .banner__title {
    margin: 0;
    max-height: 120px;
    overflow: hidden; }
  .banner__more {
    align-self: flex-end; }

.banner-stock {
  margin-bottom: 30px; }
  @media (min-width: 1024px) {
    .banner-stock {
      margin-bottom: 60px; } }
  .banner-stock__link {
    display: block;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    @media (min-width: 576px) {
      .banner-stock__link {
        background-size: contain; } }
  .banner-stock__slide {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: transparent; }
    @media (min-width: 1200px) {
      .banner-stock__slide {
        max-width: 1440px;
        height: 240px; } }
  .banner-stock__prev, .banner-stock__next {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 1; }
    .banner-stock__prev:hover, .banner-stock__next:hover {
      opacity: 0.6; }
    .banner-stock__prev::after, .banner-stock__next::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      margin: auto 0; }
  .banner-stock__prev {
    left: 15px; }
    @media (min-width: 1024px) {
      .banner-stock__prev {
        left: 30px; } }
    .banner-stock__prev::after {
      left: 0;
      width: 15px;
      height: 15px;
      border-top: 2px solid white;
      border-right: 2px solid white;
      transform: rotate(-135deg); }
      @media (min-width: 1024px) {
        .banner-stock__prev::after {
          width: 30px;
          height: 30px;
          border-top: 2px solid white;
          border-right: 2px solid white;
          transform: rotate(-135deg); } }
  .banner-stock__next {
    right: 15px; }
    @media (min-width: 1024px) {
      .banner-stock__next {
        right: 30px; } }
    .banner-stock__next::after {
      right: 0;
      width: 15px;
      height: 15px;
      border-top: 2px solid white;
      border-right: 2px solid white;
      transform: rotate(45deg); }
      @media (min-width: 1024px) {
        .banner-stock__next::after {
          width: 30px;
          height: 30px;
          border-top: 2px solid white;
          border-right: 2px solid white;
          transform: rotate(45deg); } }
  .banner-stock__pagination {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    margin: 0 auto;
    display: flex;
    justify-content: center; }
  .banner-stock .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 6px;
    border-radius: 100%; }
  .banner-stock .swiper-pagination-bullet-active {
    background-color: white; }

.illustration {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px; }
  @media (min-width: 1024px) {
    .illustration {
      grid-template-columns: repeat(2, 1fr); } }
  .illustration_mb {
    margin-bottom: 40px; }
  .illustration__text {
    margin-top: 0;
    margin-bottom: 15px;
    line-height: 1.6;
    font-size: 18px; }
    @media (min-width: 1024px) {
      .illustration__text {
        margin-bottom: 40px; } }
    .illustration__text strong {
      margin-right: 5px; }
  .illustration__button {
    max-width: 215px; }
  .illustration__pic {
    order: -1;
    position: relative;
    width: 100%;
    height: 100%; }
    @media (min-width: 1024px) {
      .illustration__pic {
        order: initial; } }
    .illustration__pic::before {
      position: absolute;
      content: '';
      top: 20px;
      left: 20px;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      background-color: transparent;
      border: 1px solid white; }
    .illustration__pic img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center top; }
      @media (min-width: 1360px) {
        .illustration__pic img {
          object-fit: cover;
          object-position: center; } }

.step {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px; }
  @media (min-width: 1024px) {
    .step {
      grid-template-columns: 40% 1fr; } }
  .step_mb {
    margin-bottom: 15px; }
    @media (min-width: 1024px) {
      .step_mb {
        margin-bottom: 50px; } }
  .step__pic {
    display: none; }
    @media (min-width: 1024px) {
      .step__pic {
        display: block;
        width: 100%;
        height: 100%; }
        .step__pic img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center top; } }
  .step__list {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .step__item {
    margin-bottom: 15px; }
    @media (min-width: 1024px) {
      .step__item {
        margin-bottom: 30px; } }
    .step__item:last-child {
      margin-bottom: 0; }
  .step__num {
    display: inline-block;
    margin-bottom: 10px;
    color: #f55e21; }
  .step__title {
    margin-top: 0;
    margin-bottom: 20px;
    font-family: 'Akrobat', sans-serif;
    font-size: 24px;
    color: #679100; }
    @media (min-width: 768px) {
      .step__title {
        font-size: 36px; } }
  .step__text strong {
    margin-left: 5px;
    margin-right: 5px; }

.three-cards {
  position: relative;
  padding-top: 30px; }
  @media (min-width: 768px) {
    .three-cards {
      padding-top: 45px; } }
  .three-cards::before, .three-cards::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 380px; }
  .three-cards::before {
    z-index: -2;
    background: url("../images/patterns/green.png") center/40%; }
  .three-cards::after {
    z-index: -1;
    background: radial-gradient(62.56% 114.43% at 35.74% 34.9%, #A4BA04 0%, black 100%);
    opacity: .3; }
  .three-cards_mb {
    margin-bottom: 33px; }
  .three-cards__title {
    margin: 0 auto 30px auto;
    color: white;
    text-align: center; }
    @media (min-width: 1024px) {
      .three-cards__title {
        margin: 0 auto 85px auto; } }
  .three-cards__wrap {
    display: flex;
    flex-direction: column; }
    @media (min-width: 1360px) {
      .three-cards__wrap {
        flex-direction: row; } }
    .three-cards__wrap_analyze {
      align-items: center; }
      @media (min-width: 1360px) {
        .three-cards__wrap_analyze {
          align-items: flex-start;
          justify-content: center; } }
      .three-cards__wrap_analyze > * {
        margin-bottom: 15px; }
        @media (min-width: 1360px) {
          .three-cards__wrap_analyze > * {
            margin-bottom: 0;
            margin-right: 30px; } }
        .three-cards__wrap_analyze > *:last-child {
          margin-bottom: 0; }
          @media (min-width: 1360px) {
            .three-cards__wrap_analyze > *:last-child {
              margin-right: 0; } }

.droppers-available {
  position: relative;
  padding-top: 30px; }
  @media (min-width: 768px) {
    .droppers-available {
      padding-top: 45px; } }
  .droppers-available::before, .droppers-available::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 380px; }
  .droppers-available::before {
    z-index: -2;
    background: url("../images/patterns/green.png") center/40%; }
  .droppers-available::after {
    z-index: -1;
    background: radial-gradient(62.56% 114.43% at 35.74% 34.9%, #A4BA04 0%, black 100%);
    opacity: .3; }
  .droppers-available_mb {
    margin-bottom: 20px; }
  .droppers-available__title {
    margin: 0 auto 30px auto;
    color: white;
    text-align: center; }
  .droppers-available__warning {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    opacity: 0.2; }
  .droppers-available__list {
    margin: 0 auto;
    padding: 0;
    list-style: none; }
  .droppers-available__item {
    margin-bottom: 20px;
    background: url("../../images/droppers/back.png") right bottom no-repeat #E9EFE8; }
  .droppers-available__header {
    padding: 160px 40px 20px; }
  .droppers-available__subtitle {
    margin: 0;
    margin-bottom: 15px;
    font-size: 36px;
    font-weight: 700;
    color: #F55E21; }
  .droppers-available__desc {
    margin: 0;
    line-height: 150%; }
  .droppers-available__sub-list {
    margin-bottom: 35px;
    padding-left: 2%;
    list-style: disc; }
  .droppers-available__sub-item {
    color: #434343;
    font-weight: 600;
    margin-top: 15px; }
    .droppers-available__sub-item::marker {
      color: #ff0000; }
  .droppers-available__body {
    padding: 0 40px 20px 40px;
    box-shadow: 0px -19px 40px #ffffff66; }
  .droppers-available__footer {
    display: flex;
    flex-wrap: wrap;
    padding: 0 40px 40px 40px; }
  .droppers-available__column {
    display: flex;
    flex-direction: column;
    margin-right: 50px; }
    .droppers-available__column-text {
      margin-bottom: 5px; }
    .droppers-available__column-number {
      font-size: 36px;
      color: #F55E21;
      font-weight: 700; }
      .droppers-available__column-number_bg-number {
        margin-left: auto;
        font-size: 50px; }
    .droppers-available__column_one-row {
      margin-left: auto;
      margin-right: 0;
      font-size: 62px;
      color: #FF6230;
      font-weight: 700; }
    .droppers-available__column_last-column {
      flex: 1 1 0%;
      margin-right: 0;
      justify-content: center; }
    .droppers-available__column-item {
      display: flex;
      align-items: center; }
  .droppers-available__column-Price {
    display: flex;
    flex-direction: column; }
    .droppers-available__column-Price small {
      font-size: 18px;
      display: block;
      margin-bottom: 5px; }
  @media (max-width: 1500px) {
    .droppers-available__column-item {
      align-items: end;
      flex-direction: column; } }
  @media (max-width: 1200px) {
    .droppers-available__column {
      margin-bottom: 20px; }
      .droppers-available__column_last-column {
        flex: 1 1 100%;
        margin-right: 0; }
      .droppers-available__column_one-row {
        margin-left: 0; }
      .droppers-available__column-number_bg-number {
        margin-left: inherit; } }
  @media (max-width: 680px) {
    .droppers-available__header {
      padding: 40px 40px 0px 40px; } }
  @media (max-width: 500px) {
    .droppers-available__header {
      padding: 20px 40px 0px 40px;
      background-size: cover; }
    .droppers-available__subtitle {
      font-size: 24px; }
    .droppers-available__column-number {
      font-size: 24px; }
    .droppers-available__column_one-row {
      font-size: 50px; } }
  @media (max-width: 376px) {
    .droppers-available__sub-item {
      font-size: 16px; } }

.border-green {
  width: 100%;
  height: 2px;
  margin-top: 20px;
  background-color: #608D00; }

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #e9efe8; }
  @media (min-width: 1024px) {
    .card {
      width: 460px;
      padding: 25px; } }
  @media (min-width: 1360px) {
    .card {
      width: 337px;
      padding: 28px 20px 46px 20px; } }
  .card_main {
    position: relative;
    z-index: 1;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: white;
    border: 13px solid rgba(103, 145, 0, 0.2); }
    @media (min-width: 1024px) {
      .card_main {
        width: 485px;
        margin: -50px -13px -13px; } }
  .card__title {
    position: relative;
    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-family: 'Akrobat', Arial, sans-serif;
    font-size: 24px;
    color: #f55e21;
    text-align: center; }
    @media (min-width: 768px) {
      .card__title {
        font-size: 24px; } }
    .card__title::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      height: 1px;
      background-color: #679100; }
  .card__price {
    color: #FF6230;
    font-size: 36px;
    font-weight: bold;
    font-family: Akrobat;
    margin-top: 16px;
    text-align: right; }
  @media (min-width: 1024px) {
    .card__list .list__item {
      line-height: 1.5; } }
  .card__list .list__item-icon {
    background-image: url("../../svg/icons/perf.svg");
    background-repeat: no-repeat;
    background-position: left 8%; }
    .card__list .list__item-icon::before {
      background-color: inherit; }
  .card__description {
    font-size: 14px;
    line-height: 120%;
    margin-top: 21px; }
  .card__button {
    display: block;
    font-weight: 600;
    text-align: center;
    font-size: 14px;
    color: #FF6230;
    padding: 12px;
    width: 100%;
    height: auto;
    margin-top: 20px;
    transition: all .1s ease-in; }
    .card__button:hover {
      background-color: #679100;
      color: #fff;
      transition: all .1s ease-in; }
  .card__btns {
    margin-top: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px; }
    @media (min-width: 768px) {
      .card__btns {
        grid-template-columns: repeat(2, 1fr); } }
    @media (min-width: 1360px) {
      .card__btns {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px; } }
    .card__btns .button {
      text-transform: none; }

.cards-wrap {
  margin: 0 -15px;
  align-items: baseline; }
  @media (min-width: 1360px) {
    .cards-wrap .cards-list {
      padding: 28px 30px 25px; } }
  @media (min-width: 1025px) {
    .cards-wrap .cards-list {
      width: 337px;
      margin: 0 15px; } }
  .cards-wrap .cards-list .card__title {
    min-height: 101px;
    font-size: 24px; }
    .cards-wrap .cards-list .card__title::before {
      width: 100%;
      height: 1px; }
  .cards-wrap .cards-list .list__item {
    background-image: url("../../svg/icons/perf.svg");
    background-repeat: no-repeat;
    background-position: left 7%; }
    .cards-wrap .cards-list .list__item::before {
      display: none; }
  .cards-wrap .cards-list .cards-list-price {
    font-family: Akrobat;
    font-weight: bold;
    font-size: 36px;
    text-align: right;
    letter-spacing: 0.05em;
    color: #FF6230;
    padding-top: 16px;
    border-top: 1px solid #608D00; }
  .cards-wrap .cards-list .button-cards {
    text-align: center;
    letter-spacing: 0.05em;
    color: #FF6230;
    height: auto;
    padding: 14px;
    margin-top: 20px;
    transition: all .1s ease-in; }
    .cards-wrap .cards-list .button-cards:hover {
      background-color: #679100;
      color: #fff;
      transition: all .1s ease-in; }
  .cards-wrap .cards-list .cards-list-descr {
    margin-top: 21px;
    font-family: Inter;
    font-size: 14px;
    min-height: 80px; }

.medical-home {
  margin-top: 53px;
  padding-bottom: 35px; }
  .medical-home__title {
    font-family: Akrobat;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #434343; }
  .medical-home__list {
    column-count: 2;
    font-size: 14px;
    padding: 0; }
    @media (min-width: 550px) {
      .medical-home__list {
        column-count: 3;
        font-size: 18px; } }
  .medical-home__item {
    margin-bottom: 5px;
    padding-left: 25px;
    position: relative;
    line-height: 164%; }
    .medical-home__item::marker {
      font-size: 0; }
    .medical-home__item::before {
      content: "";
      position: absolute;
      left: 0%;
      top: 50%;
      width: 10px;
      height: 10px;
      background: #E9EFE8;
      border-radius: 50%;
      transform: translate(-50%, -50%); }

.frame__head.text-center {
  justify-content: center; }

.content__btn-wrap.mb20 {
  margin-bottom: 30px; }

.frame {
  padding: 15px;
  background: url("../images/patterns/white.png") center; }
  @media (min-width: 1024px) {
    .frame {
      padding: 30px; } }
  .frame_green-board {
    background-image: url("../images/patterns/green.png"); }
  .frame_mb {
    margin-bottom: 25px; }
    @media (min-width: 1024px) {
      .frame_mb {
        margin-bottom: 60px; } }
  .frame__content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: white; }
    @media (min-width: 1024px) {
      .frame__content {
        padding: 30px 30px 25px 55px; } }
    .frame__content_center {
      display: flex;
      flex-direction: column;
      align-items: center; }
  .frame__head {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding-bottom: 13px;
    border-bottom: 4px solid #e9efe8; }
    @media (min-width: 1024px) {
      .frame__head {
        flex-direction: row; } }
  .frame__titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    margin-bottom: 15px; }
    @media (min-width: 1024px) {
      .frame__titles {
        margin-bottom: 0; } }
  .frame__title {
    margin-top: 0;
    margin-bottom: 0; }
  .frame__subtitle {
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Akrobat', Arial, sans-serif;
    font-size: 24px;
    color: #679100; }
    @media (min-width: 1024px) {
      .frame__subtitle {
        font-size: 36px; } }
  .frame__partner {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .frame__partner {
        flex-direction: row; } }
    @media (min-width: 1024px) {
      .frame__partner {
        flex-direction: column;
        margin-left: auto; } }
    @media (min-width: 1360px) {
      .frame__partner {
        flex-direction: row; } }
  .frame__logo-descr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px; }
  .frame__logo-text {
    width: 100%;
    height: 50px;
    margin-bottom: 5px;
    background: url("../svg/partner-logo/partner-logo.svg") center/contain no-repeat; }
    @media (min-width: 768px) {
      .frame__logo-text {
        width: 285px; } }
  .frame__link {
    margin-left: 5px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #679100;
    text-transform: uppercase; }
    @media (min-width: 1360px) {
      .frame__link {
        margin-bottom: 0; } }
  .frame__logo {
    width: 100%;
    height: 130px;
    background: url("../svg/partner-logo/partner-logo-2.svg") center/contain no-repeat; }
    @media (min-width: 768px) {
      .frame__logo {
        width: 266px; } }
  .frame__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px; }
    @media (min-width: 768px) {
      .frame__list {
        grid-template-columns: repeat(2, 1fr); } }
    @media (min-width: 1024px) {
      .frame__list {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 50px; } }
  .frame__pic {
    height: 65px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain; }
    .frame__pic_bed {
      width: 100px;
      background-image: url("../svg/icons/bed.svg"); }
    .frame__pic_night {
      width: 96px;
      background-image: url("../svg/icons/night.svg"); }
    .frame__pic_lunch {
      width: 96px;
      background-image: url("../svg/icons/lunch.svg"); }
    .frame__pic_write {
      width: 112px;
      background-image: url("../svg/icons/write.svg"); }
  .frame__little-title {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600; }
    @media (min-width: 768px) {
      .frame__little-title {
        min-height: 23px; } }
  .frame__text {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.5; }
  .frame__btn-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .frame__btn-wrap {
        flex-direction: row;
        margin-bottom: 30px; } }
    .frame__btn-wrap > * {
      flex: 0 0 auto;
      margin-bottom: 15px; }
      @media (min-width: 768px) {
        .frame__btn-wrap > * {
          max-width: 220px;
          margin-bottom: 0;
          margin-right: 30px; } }
      .frame__btn-wrap > *:last-child {
        margin-right: 0; }
  .frame__numbers {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column; }
    @media (min-width: 1360px) {
      .frame__numbers {
        margin-bottom: 30px;
        flex-direction: row; } }
  .frame__num {
    margin-bottom: 15px;
    font-family: 'Akrobat', Arial, sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    color: #f55e21;
    text-decoration: none; }
    @media (min-width: 768px) {
      .frame__num {
        font-size: 56px; } }
    @media (min-width: 1360px) {
      .frame__num {
        margin-bottom: 0;
        margin-right: 30px; } }
    .frame__num:last-child {
      margin-right: 0; }
  .frame__strong {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    text-align: center; }
    .frame__strong_ttu {
      text-transform: uppercase; }
  .frame__body_achivment {
    min-height: 240px; }
    @media (min-width: 1024px) {
      .frame__body_achivment {
        padding-right: 340px; } }
    @media (min-width: 1024px) {
      .frame__body_achivment::before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
        width: 536px;
        height: 100%;
        background: url("../svg/icons/achivment.svg") center right/contain no-repeat; } }

.costs {
  position: relative;
  padding-top: 30px; }
  @media (min-width: 768px) {
    .costs {
      padding-top: 45px; } }
  .costs::before, .costs::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 160px; }
  .costs::before {
    z-index: -2;
    background: url("../images/patterns/orange.png") center/40%; }
  .costs::after {
    z-index: -1;
    background: radial-gradient(62.56% 114.43% at 35.74% 34.9%, #FF6230 0%, black 100%);
    opacity: .3; }
  .costs_mb {
    margin-bottom: 33px; }
  .costs__title {
    margin-top: 0;
    margin-bottom: 15px;
    color: white;
    text-align: center; }
    @media (min-width: 1024px) {
      .costs__title {
        margin-bottom: 30px; } }

.stock-cards {
  position: relative;
  margin-bottom: 30px;
  padding-top: 30px; }
  @media (min-width: 768px) {
    .stock-cards {
      margin-bottom: 50px;
      padding-top: 45px; } }
  .stock-cards::before, .stock-cards::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 380px; }
  .stock-cards::before {
    z-index: -2;
    background: url("../images/patterns/white.png") center/40%; }
  .stock-cards::after {
    z-index: -1;
    background: radial-gradient(62.56% 114.43% at 35.74% 34.9%, #FFFFFF 0%, #FFFFFF 100%);
    opacity: .3; }
  .stock-cards__body {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px; }
    @media (min-width: 1024px) {
      .stock-cards__body {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px; } }
  .stock-cards__footer {
    margin-top: -2px;
    border-top: 2px solid #e9efe8; }
    @media (min-width: 992px) {
      .stock-cards__footer {
        margin-top: 40px; } }
  .stock-cards__btn {
    width: 100%;
    margin-top: -2px; }
    @media (min-width: 576px) {
      .stock-cards__btn {
        width: 250px; } }

.stock-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 2px solid #e9efe8;
  text-decoration: none;
  color: #434343; }
  .stock-card__head {
    position: relative;
    height: 210px; }
    .stock-card__head img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .stock-card__bookmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 125px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    background-color: #f55e21;
    clip-path: polygon(0 0, 100% 0%, calc(100% - 23px) 100%, 0% 100%);
    font-family: 'Akrobat', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: white;
    text-transform: uppercase; }
  .stock-card__body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 15px; }
    @media (min-width: 1024px) {
      .stock-card__body {
        padding: 25px; } }
  .stock-card__title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 28px;
    font-family: 'Akrobat', Arial, sans-serif;
    font-weight: 700;
    line-height: 1;
    color: #f55e21; }
    @media (min-width: 768px) {
      .stock-card__title {
        margin-bottom: 35px;
        font-size: 36px; } }
    .stock-card__title_little {
      font-size: 28px; }
  .stock-card__text {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1; }
  .stock-card__more {
    margin-top: auto;
    font-size: 14px;
    font-weight: 600;
    color: #f55e21; }

.vs {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(191.27deg, #FF6A29 37.52%, #CE2F03 92.08%);
  font-family: 'Akrobat', Arial, sans-serif;
  color: white;
  font-weight: 600;
  text-transform: uppercase; }
  @media (min-width: 1360px) {
    .vs {
      flex-direction: row;
      height: 270px; } }
  .vs_mb {
    margin-bottom: 40px; }
  .vs__first {
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(60% 320% at 85% 20%, rgba(164, 186, 4, 0.6) 0%, #608D00 100%), url("../images/other/pattern.png") center/150%; }
    @media (min-width: 1360px) {
      .vs__first {
        width: 45%;
        clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%); } }
  .vs__second {
    position: relative;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media (min-width: 1360px) {
      .vs__second {
        width: 55%; } }
  .vs__icon {
    display: none; }
    @media (min-width: 1360px) {
      .vs__icon {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-65%);
        margin: auto 0;
        width: 150px;
        height: 150px;
        background: url("../svg/icons/vs.svg") center/contain no-repeat; } }
  .vs__title {
    margin: 0;
    font-size: 50px;
    text-align: center; }
    @media (min-width: 1024px) {
      .vs__title {
        font-size: 105px; } }
  .vs__text {
    max-width: 95%;
    margin: 0;
    font-size: 16px;
    text-align: center; }
    @media (min-width: 1024px) {
      .vs__text {
        font-size: 28px; } }
    .vs__text_big {
      font-size: 24px; }
      @media (min-width: 1024px) {
        .vs__text_big {
          font-size: 36px; } }

.programme-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  font-family: 'Akrobat', Arial, sans-serif;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  background: radial-gradient(62.56% 114.43% at 35.74% 34.9%, #FF6230 0%, rgba(255, 98, 48, 0) 100%), url("../images/patterns/orange.png"); }
  @media (min-width: 768px) {
    .programme-price {
      flex-direction: row; } }
  @media (min-width: 1024px) {
    .programme-price {
      padding: 35px 70px; } }
  .programme-price_mb {
    margin-bottom: 40px; }
  .programme-price__text {
    flex: 0 0 auto;
    margin-right: 30px; }
  .programme-price__cost {
    flex: 0 0 auto;
    margin-left: auto;
    font-size: 50px; }
    @media (min-width: 1024px) {
      .programme-price__cost {
        font-size: 83px; } }
  .programme-price__subtitle {
    margin: 0;
    font-size: 18px; }
    @media (min-width: 1024px) {
      .programme-price__subtitle {
        font-size: 24px; } }
  .programme-price__title {
    margin: 0;
    font-size: 24px; }
    @media (min-width: 1024px) {
      .programme-price__title {
        font-size: 36px; } }

.business-card_mb {
  margin-bottom: 40px; }

.business-card__body {
  background: url("../images/patterns/white.png"); }

.business-card__footer {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(62.56% 114.43% at 35.74% 34.9%, #FF6230 0%, rgba(255, 98, 48, 0) 100%), url("../images/patterns/orange.png"); }
  @media (min-width: 1024px) {
    .business-card__footer {
      padding: 30px; } }

.business-card__btn {
  width: 100%;
  max-width: 540px; }

.business-card__content {
  display: flex;
  align-items: flex-end; }

.business-card__text-wrap {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  padding: 15px; }
  @media (min-width: 1024px) {
    .business-card__text-wrap {
      padding: 50px; } }

.business-card__title {
  margin-top: 0;
  margin-bottom: auto;
  font-family: 'Akrobat', Arial, sans-serif;
  font-weight: 600;
  font-size: 32px;
  text-transform: uppercase;
  line-height: 1; }
  @media (min-width: 1024px) {
    .business-card__title {
      font-size: 72px; } }
  .business-card__title_little {
    font-size: 24px; }
    @media (min-width: 1024px) {
      .business-card__title_little {
        font-size: 48px; } }

.business-card__doctors {
  padding: 15px; }
  @media (min-width: 1024px) {
    .business-card__doctors {
      padding: 50px 80px; } }
  .business-card__doctors > * {
    margin-bottom: 30px; }
    .business-card__doctors > *:last-child {
      margin-bottom: 0; }

.business-card__text {
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: 600; }
  .business-card__text_mt {
    margin-top: 20px; }

.business-card__name {
  margin-bottom: 25px;
  font-family: 'Akrobat', Arial, sans-serif;
  font-weight: 600;
  font-size: 36px;
  color: #679100; }

.business-card__descr {
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 18px; }

.business-card__photo {
  display: none; }
  @media (min-width: 1024px) {
    .business-card__photo {
      display: block;
      width: 460px;
      height: 500px;
      margin-left: auto; }
      .business-card__photo img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom right; } }

.doctor-info {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 1024px) {
    .doctor-info {
      flex-direction: row; } }
  .doctor-info__img {
    flex: 0 0 auto;
    width: 260px;
    height: 260px;
    margin-bottom: 20px; }
    @media (min-width: 1024px) {
      .doctor-info__img {
        margin-right: 30px;
        margin-bottom: 0; } }
    .doctor-info__img img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center; }
  .doctor-info__info {
    display: flex;
    flex-direction: column; }
  .doctor-info__text {
    margin-bottom: 5px;
    font-size: 22px;
    font-weight: 600; }
  .doctor-info__name {
    margin-top: 0;
    margin-bottom: 25px;
    font-family: 'Akrobat', Arial, sans-serif;
    font-weight: 600;
    font-size: 36px;
    color: #679100; }
  .doctor-info__descr {
    margin-top: 25px;
    margin-bottom: 0;
    font-size: 18px; }

.link-price {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  border: 2px solid #f55e21; }
  @media (min-width: 1024px) {
    .link-price {
      height: 60px;
      margin-bottom: 30px; } }
  .link-price a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #f55e21;
    text-transform: uppercase;
    text-decoration: none; }
    @media (min-width: 768px) {
      .link-price a {
        font-size: 18px; } }
    .link-price a::before {
      content: '';
      width: 32px;
      height: 21px;
      margin-right: 10px;
      background: url("../svg/icons/cloud.svg") center/contain no-repeat; }

.card-analyze {
  position: relative;
  width: 100%;
  max-width: 460px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #e9efe8;
  font-size: 18px;
  line-height: 1.5;
  color: #434343; }
  @media (min-width: 1024px) {
    .card-analyze {
      width: 460px;
      padding: 25px; } }
  @media (min-width: 1360px) {
    .card-analyze {
      width: 460px;
      padding: 36px 40px 25px; } }
  .card-analyze__head {
    margin-bottom: 15px; }
  .card-analyze__title {
    max-width: 200px;
    height: 60px;
    margin-top: 45px;
    overflow: hidden; }
    @media (min-width: 1360px) {
      .card-analyze__title {
        height: 100px; } }
  .card-analyze__img {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 80px;
    height: 80px; }
    @media (min-width: 1900px) {
      .card-analyze__img {
        top: 40px;
        right: 55px; } }
  .card-analyze__text {
    margin: 0; }
    .card-analyze__text_max-height {
      height: 81px;
      display: grid;
      grid-template-columns: 1fr;
      overflow: hidden; }
  .card-analyze__list {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-bottom: 60px; }
  .card-analyze__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .card-analyze__item {
        margin-bottom: 20px; } }
    .card-analyze__item:last-child {
      margin-bottom: 0; }
  .card-analyze__descr {
    font-weight: 600; }
  .card-analyze__footer {
    display: flex;
    margin-top: auto;
    padding-top: 15px;
    border-top: 1px solid #679100; }
  .card-analyze__price {
    margin-left: auto;
    font-family: 'Akrobat', Arial, sans-serif;
    font-size: 36px;
    color: #f55e21; }
    @media (min-width: 768px) {
      .card-analyze__price {
        font-size: 62px; } }
  .card-analyze__spoiler-head {
    cursor: pointer;
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #f55e21;
    font-size: 14px;
    transition: all .2s ease-in; }
  .card-analyze__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
    width: 14px;
    height: 14px; }
    .card-analyze__icon::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto 0;
      width: 100%;
      height: 2px;
      background-color: #f55e21; }
    .card-analyze__icon::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      width: 2px;
      height: 100%;
      background-color: #f55e21; }

.spoiler.open .card-analyze__spoiler-head {
  margin-bottom: 15px;
  border-bottom: 1px solid #679100;
  transition: all .2s ease-in; }

.spoiler.open .card-analyze__icon::after {
  content: none; }

.callback-banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px 30px;
  background: url("../images/other/callback-banner.jpg") center/cover no-repeat; }
  .callback-banner_mb {
    margin-bottom: 30px; }
  .callback-banner__title {
    margin-bottom: 10px;
    text-align: center; }
  .callback-banner__subtitle {
    margin-top: 0;
    margin-bottom: 30px;
    font-family: 'Akrobat', Arial, sans-serif;
    color: white;
    font-size: 24px;
    font-weight: 600; }
    @media (min-width: 768px) {
      .callback-banner__subtitle {
        font-size: 28px; } }
    @media (min-width: 1360px) {
      .callback-banner__subtitle {
        font-size: 45px; } }
  .callback-banner__btns {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 470px; }
    @media (min-width: 768px) {
      .callback-banner__btns {
        flex-direction: row; } }
    .callback-banner__btns > * {
      margin-bottom: 10px; }
      @media (min-width: 768px) {
        .callback-banner__btns > * {
          margin-right: 30px;
          margin-bottom: 0; } }
      .callback-banner__btns > *:last-child {
        margin-bottom: 0;
        margin-right: 0; }

.doctor-to-home {
  overflow: hidden; }
  .doctor-to-home__scheme {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 40px;
    border-bottom: 4px solid #e9efe8; }
    @media (min-width: 1024px) {
      .doctor-to-home__scheme {
        flex-direction: row;
        justify-content: space-around; } }
  .doctor-to-home__arrow {
    width: 180px;
    height: 73px;
    margin-top: 80px;
    margin-bottom: 80px;
    transform: rotate(90deg);
    background: url("../svg/doctor-to-home/arrow.svg") center/contain no-repeat; }
    @media (min-width: 1024px) {
      .doctor-to-home__arrow {
        transform: rotate(0deg);
        margin-right: 20px;
        margin-bottom: 0;
        margin-top: 0; } }
    .doctor-to-home__arrow_first, .doctor-to-home__arrow_last {
      position: absolute;
      top: 0;
      bottom: 40px;
      margin: auto 0;
      z-index: -1; }
    .doctor-to-home__arrow_first {
      right: calc(100% - 35px); }
    .doctor-to-home__arrow_last {
      left: calc(100% - 35px); }
  .doctor-to-home__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; }
    @media (min-width: 1024px) {
      .doctor-to-home__block {
        margin-right: 20px;
        margin-bottom: 0; } }
    .doctor-to-home__block:last-child {
      margin-bottom: 0;
      margin-right: 0; }
  .doctor-to-home__pic {
    width: 176px;
    height: 167px;
    margin-bottom: 10px; }
    .doctor-to-home__pic_call {
      background: url("../svg/doctor-to-home/call.svg") center/contain no-repeat; }
    .doctor-to-home__pic_operator {
      background: url("../svg/doctor-to-home/operator.svg") center/contain no-repeat; }
    .doctor-to-home__pic_car {
      background: url("../svg/doctor-to-home/car.svg") center/contain no-repeat; }
  .doctor-to-home__text {
    text-align: center;
    font-weight: 600;
    font-size: 20px; }
  .doctor-to-home__info {
    display: flex;
    flex-direction: column;
    align-items: center; }

.menu-other__wrapper {
  min-height: 165px; }

.menu-other__row {
  display: flex;
  border-bottom: 2px solid #E9EFE8; }
  @media (max-width: 1199px) {
    .menu-other__row {
      flex-direction: column; } }

.menu-other__btn-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none; }

.menu-other__add-link {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #434343;
  padding-bottom: 20px; }
  .menu-other__add-link::before {
    content: '';
    width: 23px;
    height: 22px;
    margin-right: 4px;
    background-image: url("../svg/icons/compleate.svg");
    background-repeat: no-repeat;
    background-size: contain; }
  @media (max-width: 1199px) {
    .menu-other__add-link {
      justify-content: center;
      margin-top: 10px; } }

.menu-other__btn-item {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 4px solid #e9efe800; }
  .menu-other__btn-item:not(:last-child) {
    margin-right: 20px; }
    @media (min-width: 585px) {
      .menu-other__btn-item:not(:last-child) {
        margin-right: 100px; } }
  @media (min-width: 830px) {
    .menu-other__btn-item {
      margin-bottom: 0; } }

.menu-other__column {
  display: none;
  flex-wrap: wrap; }
  .menu-other__column.active {
    display: flex; }

.menu-other__btn-link {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #434343;
  padding-bottom: 20px; }
  .menu-other__btn-link.active {
    border-bottom: 4px solid #608D00; }

.menu-other__list {
  list-style: none;
  padding: 0; }
  @media (min-width: 1024px) {
    .menu-other__list {
      -moz-column-count: 3;
      -moz-column-width: 200px;
      -webkit-column-count: 3;
      -webkit-column-width: 200px;
      column-count: 3;
      column-width: 465px; } }

.menu-other__item {
  display: flex;
  margin-bottom: 25px; }

.menu-other__icon {
  display: inline-block;
  font-size: 16px;
  color: #FF6230; }

.menu-other__link {
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  color: #434343;
  text-decoration: none;
  transition: color .3s; }
  .menu-other__link:hover {
    color: #FF6230;
    transition: color .3s; }

.ml {
  margin-left: 28px; }

.cost-calculate {
  margin-bottom: 30px; }
  .cost-calculate-container {
    display: flex;
    flex-direction: column;
    margin: 2px 0;
    border: 2px solid #E9EFE8; }
    .cost-calculate-container:nth-child():not(:last-child)__header {
      margin-bottom: 2px; }
  .cost-calculate__header {
    display: flex;
    padding: 18px 38px;
    align-items: center;
    background: #E9EFE8; }
    .cost-calculate__header-title {
      margin: 0;
      color: #434343;
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0.05em; }
      @media (max-width: 414px) {
        .cost-calculate__header-title {
          font-size: 16px; } }
    .cost-calculate__header-addBtn {
      display: block;
      max-width: 20px;
      width: 100%;
      height: 20px;
      margin-left: auto;
      background-image: url("../svg/icons/icon-open.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
  .cost-calculate__body {
    padding: 25px 38px; }
    .cost-calculate__body-allBtn {
      padding: 12px 17px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #FF6230;
      border: 2px solid #E9EFE8;
      transition: background-color .1s ease-in, border .1s ease-in; }
      .cost-calculate__body-allBtn:hover {
        border: 2px solid #679100;
        background-color: #679100;
        color: white; }
    .cost-calculate__body-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -32px;
      padding: 0;
      margin-top: 25px;
      list-style: none; }
    .cost-calculate__body-item {
      width: 100%;
      max-width: 290px;
      margin: 0 32px;
      margin-bottom: 20px; }
  .cost-calculate__checkbox {
    display: flex;
    cursor: pointer; }
    .cost-calculate__checkbox-hidden {
      display: none; }
      .cost-calculate__checkbox-hidden:checked ~ .cost-calculate__checkbox-icon::after {
        background-color: #FF6230; }
    .cost-calculate__checkbox-icon {
      position: relative;
      display: block;
      width: 19px;
      height: 19px;
      margin-right: 13px;
      border: 1px solid #E9EFE8; }
      .cost-calculate__checkbox-icon::after {
        content: " ";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 11px;
        height: 11px;
        transform: translate(-50%, -50%);
        transition: all linear .1s; }
    .cost-calculate__checkbox-title {
      display: block;
      width: 100%; }

.border-grey {
  background-color: #E9EFE8;
  height: 2px;
  border: none;
  margin: 0; }

.text-center {
  text-align: center; }

.mt46 {
  margin-top: 46px; }

.mb27 {
  margin-bottom: 27px; }

.cost-result {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 27px;
  padding: 0;
  list-style: none; }
  .cost-result__row {
    display: flex;
    border: 2px solid #E9EFE8;
    justify-content: space-between; }
    .cost-result__row:not(:last-child) {
      border-bottom: 0; }
    .cost-result__row-left {
      display: flex;
      align-items: center;
      flex: 1 1 70%;
      max-width: 1380px;
      padding: 19px 25px 19px 38px; }
    .cost-result__row-right {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 30%;
      max-width: 60px;
      border-left: 2px solid #E9EFE8; }
    .cost-result__row-title {
      margin: 0;
      word-break: break-all;
      margin-right: auto;
      font-weight: 600;
      font-size: 14px;
      color: #434343; }
      @media (min-width: 595px) {
        .cost-result__row-title {
          font-size: 20px; } }
      @media (max-width: 595px) {
        .cost-result__row-title {
          max-width: 210px;
          padding-right: 13px; } }
    .cost-result__row-price {
      font-family: 'Akrobat';
      font-weight: bold;
      font-size: 16px;
      text-align: right;
      color: #263238; }
      @media (min-width: 595px) {
        .cost-result__row-price {
          font-size: 30px; } }
      @media (max-width: 595px) {
        .cost-result__row-price {
          align-self: baseline; } }
    .cost-result__row-del {
      display: block;
      align-self: center;
      width: 40px;
      height: 40px;
      background-image: url("../svg/icons/cost-del.svg");
      background-repeat: no-repeat;
      background-size: contain; }

.cost-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 54px;
  margin-bottom: 49px; }
  .cost-price__container {
    width: 100%;
    max-width: 580px; }

.cost-total {
  display: flex;
  align-items: baseline;
  justify-content: flex-end; }
  .cost-total__value {
    margin-right: auto;
    font-family: 'Akrobat';
    font-weight: bold;
    font-size: 28px;
    color: #263238; }
    @media (min-width: 416px) {
      .cost-total__value {
        font-size: 36px; } }
  .cost-total__price {
    font-family: 'Akrobat';
    font-weight: bold;
    font-size: 40px;
    line-height: 111.5%;
    text-align: right;
    color: #FF6230; }
    @media (min-width: 416px) {
      .cost-total__price {
        font-size: 62px; } }

.cost-call {
  display: flex;
  align-items: center;
  margin-top: 32px; }
  @media (max-width: 595px) {
    .cost-call {
      flex-direction: column; } }
  .cost-call__callback {
    display: block;
    padding: 10px 60px;
    margin-right: auto;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #fff;
    background-color: #FF6230;
    text-decoration: none;
    transition: all ease .1s; }
    .cost-call__callback:hover {
      background-color: #679100; }
    @media (max-width: 595px) {
      .cost-call__callback {
        width: 100%; } }
  .cost-call__download {
    position: relative;
    display: block;
    color: #F55E21;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    width: 100%;
    max-width: 60%;
    text-align: right;
    transition: all ease .1s; }
    .cost-call__download:hover {
      color: #679100; }
    .cost-call__download::before {
      content: '';
      position: absolute;
      width: 33px;
      height: 32px;
      background-image: url("../svg/icons/download-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
      left: 0; }
    .cost-call__download:hover::before {
      background-image: url("../svg/icons/download-icon-hover.svg"); }
    @media (max-width: 595px) {
      .cost-call__download {
        max-width: 345px;
        width: 100%;
        margin-top: 21px; } }
    @media (max-width: 415px) {
      .cost-call__download {
        font-size: 14px;
        max-width: 280px; } }

.filter-panel__analyzes {
  position: relative; }
  .filter-panel__analyzes-search:focus {
    outline: none;
    border: 2px solid #679100; }
  .filter-panel__analyzes-search.active:focus {
    border-bottom: none; }
  .filter-panel__analyzes-body {
    display: none;
    position: absolute;
    width: 100%;
    margin: 0px;
    padding: 0px;
    list-style: none;
    top: 45px;
    left: 50%;
    transform: translate(-50%);
    background-color: #fff;
    padding: 0 20px; }
    .filter-panel__analyzes-body.active {
      display: block;
      border-left: 2px solid #679100;
      border-bottom: 2px solid #679100;
      border-right: 2px solid #679100; }
  .filter-panel__analyzes-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px; }
    .filter-panel__analyzes-item:first-child {
      margin-top: 3px;
      padding-top: 3px;
      border-top: 2px solid #e9efe8; }
  .filter-panel__analyzes-link {
    display: block;
    padding: 4px 0;
    text-decoration: none;
    color: #263238;
    width: 100%;
    text-align: inherit; }

.row-btn {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 52px; }
  .row-btn__link {
    display: block;
    margin: 0 15px;
    padding: 14px 57px 12px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    color: #E9EFE8; }
    .row-btn__link--bg-orange {
      background-color: #F55E21; }
    .row-btn__link--bg-green {
      background-color: #608D00; }
    .row-btn__link--bg-yellow {
      background-color: #ACBF05; }

.home-visit-text {
  font-size: 36px;
  font-weight: 700;
  font-family: Akrobat;
  color: #434343; }

.recommended-examinations {
  margin-top: 40px;
  margin-bottom: 40px; }
  .recommended-examinations__title {
    font-family: 'Akrobat', sans-serif;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center; }
  .recommended-examinations__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px; }
  .recommended-examinations__item {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    flex-direction: column;
    flex: 1 1 40%;
    margin: 0 20px 30px;
    text-transform: uppercase;
    padding: 130px 25px 25px 25px;
    font-weight: 700;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    text-decoration: none;
    color: #434343; }
    @media (min-width: 1326px) {
      .recommended-examinations__item {
        flex: 1 1 294px;
        margin: 0 20px; } }
    .recommended-examinations__item::after {
      content: '';
      position: absolute;
      background: whitesmoke;
      background: linear-gradient(0deg, rgba(245, 245, 245, 0.916404) 0%, rgba(255, 255, 255, 0.927609) 28%, rgba(248, 255, 255, 0.672707) 55%, rgba(9, 11, 11, 0) 100%);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%; }
    .recommended-examinations__item-subtitle {
      position: relative;
      z-index: 3; }
