.recommended-examinations {
  margin-top: 40px;
  margin-bottom: 40px;
  &__title {
    font-family: 'Akrobat', sans-serif;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
  }
  &__item {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    flex-direction: column;
    flex: 1 1 40%;
    margin: 0 20px 30px;
    text-transform: uppercase;
    padding: 130px 25px 25px 25px;
    font-weight: 700;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    text-decoration: none;
    color: #434343;
    @media (min-width: 1326px) {
      flex: 1 1 294px;
      margin: 0 20px;
    }
    &::after {
      content: '';
      position: absolute;
      background: rgb(245,245,245);
      background: linear-gradient(0deg, rgba(245,245,245,0.9164040616246498) 0%, rgba(255,255,255,0.927608543417367) 28%, rgba(248,255,255,0.6727065826330532) 55%, rgba(9,11,11,0) 100%);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
    &-subtitle {
      position: relative;
      z-index: 3;
    }
  }
}