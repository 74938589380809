.our-specialists {

  &__hr {
    margin-bottom: 40px;
  }

  &__filter-panel {
    margin-bottom: 40px;
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    @media (min-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 992px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 1300px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (min-width: 1500px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}