.article {

  &_mb {
    margin-bottom: 20px;

    @media (min-width: 576px) {
      margin-bottom: 40px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__hr {
    margin-bottom: 40px;

    border: none;
    height: 2px;
    background-color: $third-color;
  }

  &__pic {
    position: relative;

    width: 100%;

    margin-bottom: 40px;

    background: url('../images/other/pattern.png') repeat;

    @media (min-width: 992px) {
      margin-bottom: 80px;
    }

    &::before {
      position: absolute;
      content: '';
      top: 20px;
      left: 0;
      right: 0;
      margin: 0 auto;

      width: calc(100% - 40px);
      height: 100%;

      border: 2px solid $third-color;

      @media (min-width: 992px) {
        top: 40px;
        width: calc(100% - 80px);
      }
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
    }
  }

  &__text {
    @media(min-width: 1200px ) {
      &.margin-slider {
        margin-top: 650px;
      }
    }
    p {
      margin-bottom: 20px;

      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-swiper-container {
    display: none;
    @media (min-width: 1200px) {
      display: block;

      position: absolute;

      left: 0;

      width: 100%;
      height: 600px;

      background: url("../images/other/pattern.png") repeat;
    }
  }
  &-slider {
    position: relative;

    width: 100%;
    max-width: 1440px;
    .swiper-wrapper {
      max-width: 100%;
      .swiper-slide {
        position: relative;

        img {
          width: 100%;
          height: 600px;

          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}