.pagination {
  @include clearList();

  margin-top: 40px;
  margin-bottom: 40px;

  display: flex;

  &__item {
    cursor: pointer;
    width: 60px;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid $third-color;
    margin-left: -2px;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      background-color: $first-color;
      color: white;

      .pagination__link {

        &_prev::before,
        &_next::before {
          border-color: white;
        }
      }
    }

    &_active {
      pointer-events: none;
      background-color: $third-color;
    }

    &_prev,
    &_next {

      .pagination__link::before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    &_prev {

      .pagination__link::before {
        left: 7px;
        @include arrow(12px, 2px, 'left', $fourth-color);
      }
    }

    &_next {

      .pagination__link::before {
        right: 7px;
        @include arrow(12px, 2px, 'right', $fourth-color);
      }
    }

    &_disabled {
      pointer-events: none;
      background-color: rgba(0, 0, 0, .1);

      .pagination__link {

        &::before {
          border-color: gray;
        }
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    text-decoration: none;
    color: inherit;
    font-weight: 500;
  }
}