.business-card {

  &_mb {
    margin-bottom: 40px;
  }

  &__body {
    background: url('../images/patterns/white.png');
  }

  &__footer {
    padding: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: radial-gradient(62.56% 114.43% at 35.74% 34.9%, #FF6230 0%, rgba(255, 98, 48, 0) 100%), url('../images/patterns/orange.png');

    @media (min-width: 1024px) {
      padding: 30px;
    }
  }

  &__btn {
    width: 100%;
    max-width: 540px;
  }

  &__content {
    display: flex;
    align-items: flex-end;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;

    margin-right: 30px;
    padding: 15px;

    @media (min-width: 1024px) {
      padding: 50px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: auto;

    font-family: 'Akrobat', Arial, sans-serif;
    font-weight: 600;
    font-size: 32px;
    text-transform: uppercase;
    line-height: 1;

    @media (min-width: 1024px) {
      font-size: 72px;
    }

    &_little {
      font-size: 24px;

      @media (min-width: 1024px) {
        font-size: 48px;
      }
    }
  }

  &__doctors {
    padding: 15px;

    @media (min-width: 1024px) {
      padding: 50px 80px;
    }

    & > * {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__text {
    margin-bottom: 5px;

    font-size: 22px;
    font-weight: 600;

    &_mt {
      margin-top: 20px;
    }
  }

  &__name {
    margin-bottom: 25px;

    font-family: 'Akrobat', Arial, sans-serif;
    font-weight: 600;
    font-size: 36px;
    color: $first-color;
  }

  &__descr {
    margin-top: 25px;
    margin-bottom: 0;

    font-size: 18px;
  }

  &__photo {
    display: none;

    @media (min-width: 1024px) {
      display: block;

      width: 460px;
      height: 500px;

      margin-left: auto;

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: bottom right;
      }
    }
  }
}
