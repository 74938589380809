.title {
  font-family: 'Akrobat', sans-serif;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;

  @media (min-width: 992px) {
    font-size: 36px;
		margin: 0;
  }

  &_no-margin {
    margin: 0;
  }

  &_red {
    color: $fourth-color;
  }

  &_green {
    color: $first-color;
  }

  &_white {
    color: white;
  }

  &_center {
    text-align: center;
  }

  &_h2 {
    margin: 0;
  }

  &_h3 {
    margin: 0;
    font-size: 24px;

    @media (min-width: 576px) {
      font-size: 28px;
    }
  }

  &__hr {
    width: 100%;
    height: 2px;

    background: $third-color;
    border: none;
  }
}
