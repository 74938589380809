.specialists {
  margin-bottom: 70px;
  overflow: hidden;

  @media (min-width: 768px) {
    margin-bottom: 30px;
  }

  &__wrapper {
    margin-bottom: -2px;

    @media (min-width: 576px) {
      margin-bottom: 40px;
    }
  }

  &__navigations {
    display: flex;
    border-top: 2px solid $third-color;
  }

  &__arrow-prev, &__arrow-next {
    width: 60px;
    height: 60px;

    flex: 0 0 auto;

    margin-top: -2px;
  }

  &__arrow-next {
    margin-left: -2px;
  }

  &__prev, &__next {
    position: relative;

    width: 100%;
    height: 100%;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  &__prev {

    &::after {
      left: 5px;
      @include arrow(12px, 2px, 'left', $fourth-color);
    }

    @media (min-width: 992px) {
      &:hover {

        &::after {
          @include arrow(12px, 2px, 'left', white);
        }
      }
    }
  }

  &__next {

    &::after {
      right: 5px;
      @include arrow(12px, 2px, 'right', $fourth-color);
    }

    @media (min-width: 992px) {
      &:hover {

        &::after {
          @include arrow(12px, 2px, 'right', white);
        }
      }
    }
  }

  &__btn {
    max-width: 240px;

    margin-top: -2px;
    margin-left: -2px;

    font-size: 14px;

    @media (min-width: 576px) {
      font-size: 16px;
    }
  }
}