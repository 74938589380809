.different {
  font-family: 'Akrobat', sans-serif;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  color: $font-color;

  &_mb {
    margin-bottom: 30px;

    @media (min-width: 768px) {
      margin-bottom: 55px;
    }
  }

  &__title {
    margin-bottom: 25px;

    font-size: 24px;
    text-align: center;
    font-weight: bold;

    @media (min-width: 992px) {
      font-size: 28px;
    }
    @media (min-width: 1200px) {
      font-size: 36px;
    }
  }

  &__head {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 0;
    padding-top: 25px;
    padding-bottom: 25px;

    border-top: 2px solid $third-color;
    border-bottom: 2px solid $third-color;

    @media (min-width: 576px) {
      border-top: 4px solid $third-color;
      border-bottom: 4px solid $third-color;
    }
    @media (min-width: 768px) {
      margin-bottom: 25px;
      flex-wrap: initial;
    }
  }

  &__title-col {
    padding-right: 20px;

    display: flex;
    justify-content: center;

    font-size: 20px;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: 992px) {
      font-size: 24px;
    }
    @media (min-width: 1200px) {
      font-size: 36px;
    }

    &_other {
      width: 100%;

      margin-bottom: 20px;
      padding: 0 20px 0;

      @media (min-width: 768px) {
        width: calc(50% - 30px);

        margin-bottom: 0;
        padding-left: 80px;
      }
    }

    &_we {
      width: 100%;
      padding: 0 20px 0;

      justify-content: center;
      color: $first-color;

      @media (min-width: 768px) {
        width: calc(50% + 30px);
        padding-left: 47px;
        justify-content: flex-start;
      }
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  &__other,
  &__we {
    height: auto;
    overflow: hidden;

    margin-bottom: 5px;
    padding: 20px;

    display: flex;
    align-items:center;

    font-size: 20px;
    line-height: 1.2;

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      height: 140px;
    }
    @media (min-width: 992px) {
      height: 160px;
      font-size: 24px;
    }
    @media (min-width: 1200px) {
      height: 190px;
      font-size: 28px;
    }
  }

  &__other {
    width: 100%;
    padding: 30px 20px 30px 60px;
    background-color: #fff;

    @media (min-width: 768px) {
      width: calc(50% - 30px);

      padding-left: 55px;
      padding-right: 20px;
    }
    @media (min-width: 992px) {
      padding: 20px;
    }
  }

  &__we {
    width: 100%;
    padding: 30px 20px 30px 60px;
    background-color: $third-color;

    @media (min-width: 768px) {
      width: calc(50% + 30px);
      padding: 20px 20px 20px 60px;
    }
    @media (min-width: 992px) {
      padding-left: 100px;
      padding-right: 80px;
    }
  }

  &__icon {
    display: none;

    @media (min-width: 768px) {
      display: initial;

      width: 60px;
      height: 50px;

      margin-right: 40px;

      flex: 0 0 auto;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    @media (min-width: 992px) {
      width: 80px;
      height: 67px;

      margin-right: 80px;
    }
    @media (min-width: 1200px) {
      width: 130px;
      height: 110px;

      margin-right: 95px;
    }
  }

  &__text {
    position: relative;

    &_minus::before {
      position: absolute;
      content: '';
      top: 5px;
      left: -25px;

      width: 15px;
      height: 15px;

      background: url('../svg/different/close.svg') center/contain no-repeat;

      @media (min-width: 1200px) {
        width: 21px;
        height: 22px;

        left: -35px;
      }
    }

    &_plus::before {
      position: absolute;
      content: '';
      top: 8px;
      left: -30px;

      width: 20px;
      height: 12px;

      background: url('../svg/different/checked.svg') center/contain no-repeat;

      @media (min-width: 768px) {
        width: 28px;
        height: 17px;

        left: -40px;
      }
      @media (min-width: 1200px) {
        width: 36px;
        height: 23px;

        left: -53px;
      }
    }
  }

  &__footer {
    border-bottom: 2px solid $third-color;

    @media (min-width: 576px) {
      border-bottom: 4px solid $third-color;
    }
  }

  &__footer-text {
    width: 100%;

    padding: 30px;

    font-size: 20px;
    color: $fourth-color;

    @media (min-width: 768px) {
      width: calc(50% + 30px);

      margin-left: calc(50% - 30px);
      padding: 40px;

      font-size: 24px;
    }
    @media (min-width: 1200px) {
      font-size: 36px;
    }
  }
}