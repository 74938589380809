* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.05em;
  color: $font-color;
  overflow-x: hidden;
}

main {
  flex: 1 1 auto;
}

video {
  outline: none;
}

input,
textarea,
select {
  &:focus {
    position: relative;
    outline: 2px solid $first-color;
    z-index: 1;
  }
}

.mb {
  margin-bottom: 30px;
  @media (min-width: 1200px) {
    margin-bottom: 60px;
  }
}