.doctor-call {
  width: 100%;

  padding: 20px;

  border: 2px solid $third-color;

  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;

  @media (min-width: 768px) {
    flex-direction: initial;
    flex-wrap: wrap;
    justify-content:space-between;

    padding: 30px 45px;
  }
  @media (min-width: 1200px) {
    justify-content: initial;
  }

  &_mb {
    margin-bottom: 30px;

    @media (min-width: 1200px) {
      margin-bottom: 60px;
    }
  }

  &__title {
    width: 100%;
    margin-right: auto;
    margin-bottom: 20px;

    font-size: 24px;

    @media (min-width: 576px) {
      font-size: 36px;
    }
    @media (min-width: 1200px) {
      width: initial;
      margin-bottom: 0;
    }
  }

  &__text {
    width: 100%;
    margin-right: auto;
    margin-bottom: 20px;

    font-family: 'Akrobat', sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.1;
    text-transform: uppercase;
    color: $font-color;
    text-align: center;

    @media (min-width: 576px) {
      font-size: 36px;
    }
    @media (min-width: 1200px) {
      width: initial;
      margin-bottom: 0;

      text-align: initial;
    }
  }

  &__alert {
    width: 100%;

    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;

    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 16px;
    line-height: 1.2;
    color: $fourth-color;
    text-align: center;

    @media (min-width: 768px) {
      width: initial;

      margin-left: 30px;
      margin-right: 20px;
      margin-bottom: 0;

      text-align: initial;
    }
    @media (min-width: 1200px) {
      font-size: 20px;
      margin-right: 50px;
    }
  }

  &__btn {
    width: 100%;

    @media (min-width: 768px) {
      width: 320px;
    }

    &_little {

      @media (min-width: 768px) {
        width: 240px;
      }
    }
  }
}
.doctor-call__title--fz-28 {
  font-size: 28px;
}
.doctor-call--mb20 {
  margin-bottom: 20px;
}