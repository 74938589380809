.result {
  margin-bottom: 30px;
  padding-bottom: 25px;
  border-bottom: 2px solid $third-color;

  @media (min-width: 576px) {
    margin-bottom: 60px;
    padding-bottom: 50px;
  }

  &:last-child {
    border-bottom: none;
  }

  &__link {
    text-decoration: none;
    color: $font-color;
  }

  &__name {
    margin-top: 0;
    margin-bottom: 30px;

    font-size: 20px;
    font-weight: 600;
  }

  &__text {
    margin-bottom: 25px;

    @media (min-width: 576px) {
      margin-bottom: 50px;
    }

    p {
      margin: 0;
    }
  }

  &__words {
    color: $fourth-color;
  }

  &__tag {
    font-weight: 600;
    font-size: 14px;
    color: $first-color;
    text-decoration: none;
  }
}