.navbar-second {
  position: relative;
  z-index: 2;

  display: flex;
  align-items: center;

  height: 50px;

  background-color: #fff;
  border-bottom: 2px solid $third-color;

  &_glue {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    margin-bottom: 0;
  }

  &__content {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__address {
    display: none;

    @media (min-width: 768px) {
      display: initial;

      margin-right: auto;
      font-size: 14px;
    }
    @media (min-width: 1400px) {
      font-size: 16px;
    }
  }

  &__logo {

    @media (min-width: 768px) {
      display: none;
    }
    &--double {
      max-width: 100px;
      width: 100%;
      object-fit: contain;
      height: auto;
      @media (min-width: 430px) {
        max-width: 150px;
      }
    }
  }

  &__navigation {
    height: 100%;
    margin-left: auto;
  }
}
