.footer {
  flex: 0 0 auto;
  margin-top: -2px;

  border-top: 2px solid $third-color;

  @media (min-width: 1200px) {
    margin-top: 0;
  }

  &__content {
    display:flex;
    flex-direction: column;

    font-size: 14px;
    font-weight: 600;

    @media (min-width: 1500px) {
      flex-direction: initial;
    }
  }

  &__info {
    display:flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    margin-bottom: 30px;
    padding-top: 15px;
    padding-right: 30px;

    @media (min-width: 1200px) {
      padding-right: 100px;
    }
    @media (min-width: 1500px) {
      margin-bottom: 0;
      padding: 40px 80px 40px 0;

      border-right: 2px solid $third-color;
    }
    &-row {
      display: flex;
      flex-wrap: wrap;
    }
    &-banner-box {
      margin-left: 15px;
      align-self: center;
    }
    &-banner-img {
      margin-right: 5px;
    }
  }

  &__logo {
    margin-bottom: 25px;

    @media (min-width: 1500px) {
      margin-bottom: 35px;
    }
  }

  &__navigation {
    margin-bottom: 30px;

    @media (min-width: 1500px) {
      margin-bottom: 60px;
    }
  }

  &__list {
    @include clearList();

    @media (min-width: 576px) {
      display: flex;
      flex-wrap: wrap;
    }
    @media (min-width: 992px) {
      flex-wrap: nowrap;
    }
  }

  &__item {
    margin-bottom: 5px;

    @media (min-width: 576px) {
      width: calc(100% / 3);
    }
    @media (min-width: 992px) {
      width: initial;

      margin-right: 15px;
      margin-bottom: 0;
    }
  }

  &__link {
    text-decoration: none;
    color: $font-color;

    @media (min-width: 992px) {
      &:hover {
        color: $second-color;
      }
    }

    h3 {
      margin: 0;
    }
  }

  &__text {
    margin: 0;

    font-size: 14px;
    color: #C2C2C2;

    @media (min-width: 576px) {
      font-size: 16px;
    }
  }

  &__media {
    width: 100%;
    padding-bottom: 30px;

    @media (min-width: 768px) {
      max-width: 500px;
    }
    @media (min-width: 1500px) {
      padding: 40px 80px 40px 80px;
    }
    @media (min-width: 1700px) {
      padding-right: 0;
    }
  }

  &__social-links {
    margin-top: 25px;
  }

}