.article-horizontal-card {
  width: 100%;

  margin-bottom: 40px;

  display: flex;
  flex-direction: column;
  border: 2px solid $third-color;

  text-decoration: none;
  color: $font-color;

  @media (min-width: 992px) {
    flex-direction: initial;
    height: 250px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__img {
    width: 100%;
    height: 250px;
    flex: 0 0 auto;

    background: url('../images/other/pattern.png') repeat;

    @media (min-width: 992px) {
      width: 425px;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;

    padding: 40px 40px 20px 40px;
    background-color: #fff;

    &:hover {
      background-color: $third-color;
    }
  }

  &__title {
    max-height: 135px;
    overflow-y: hidden;

    margin: 0;
    font-size: 20px;
  }

  &__link {
    margin-top: 20px;

    color: $fourth-color;
    font-weight: 600;
    font-size: 18px;
  }
}