.menu-other__wrapper {
  min-height: 165px;
}
.menu-other__row {
  display: flex;
  border-bottom: 2px solid #E9EFE8;
  @media(max-width: 1199px) {
    flex-direction: column;
  }
}
.menu-other__btn-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
}
.menu-other__add-link {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #434343;
  padding-bottom: 20px;
  &::before {
    content: '';

    width: 23px;
    height: 22px;

    margin-right: 4px;

    background-image: url("../svg/icons/compleate.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media(max-width: 1199px) {
    justify-content: center;
    margin-top: 10px;
  }
}
.menu-other__btn-item {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 4px solid #e9efe800;
  &:not(:last-child) {
    margin-right: 20px;
    @media(min-width: 585px) {
      margin-right: 100px;
    }
  }
  @media(min-width:830px) {
    margin-bottom: 0;
  }
}

.menu-other__column {
  display: none;
  flex-wrap: wrap;
  &.active {
    display: flex;
  }
}

.menu-other__btn-link {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #434343;
  padding-bottom: 20px;
  &.active {
    border-bottom: 4px solid #608D00;
  }
}

.menu-other__list {
  list-style: none;
  padding: 0;
  @media(min-width: 1024px) {
    -moz-column-count: 3;
    -moz-column-width: 200px;
    -webkit-column-count: 3;
    -webkit-column-width: 200px;
    column-count: 3;
    column-width: 465px;
  }
}

.menu-other__item {
  display: flex;
  margin-bottom: 25px;
}

.menu-other__icon {
  display: inline-block;
  font-size: 16px;
  color: #FF6230;
}

.menu-other__link {
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  color: #434343;
  text-decoration: none;
  transition: color .3s;
  &:hover {
    color: #FF6230;
    transition: color .3s;
  }
}

.ml {
  margin-left: 28px;
}