.amenities-cards {
  margin-top: 30px;
  margin-bottom: 50px;

  @media (min-width: 1200px) {
    margin-top: 0;
  }

  &__title {
    margin-bottom: 40px;
    color: $first-color;
  }

  &__wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin-left: -15px;
    margin-right: -15px;

    @media (min-width: 1500px) {
      justify-content: initial;
    }
  }

  &__hr {
    border-top: 2px solid $third-color;
  }

  &__btn {
    width: 195px;
    height: 70px;

    margin-top: -2px;

    font-size: 16px;
  }
}

.amenities-card {
  position: relative;

  display: flex;
  align-items: flex-end;

  width: 290px;
  height: 170px;

  margin: 0 15px 15px;
  padding: 40px 30px;

  background-blend-mode: lighten, multiply, normal;

  overflow: hidden;
  text-decoration: none;

  @media (min-width: 576px) {
    margin-bottom: 30px;
  }
  @media (min-width: 360px) {
    width: 337px;
    height: 198px;
  }

  &__title {
    font-size: 28px;
    text-transform: uppercase;
    color: $font-color;
  }
}