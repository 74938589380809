.cost-calculate {
	margin-bottom: 30px;
	&-container {
		display: flex;
		flex-direction: column;
		margin: 2px 0;
		border: 2px solid #E9EFE8;
		&:nth-child():not(:last-child) {
			&__header {
				margin-bottom: 2px;
			}
		}
	}
	&__header{
		display: flex;
		padding: 18px 38px;
		align-items: center;
		background: #E9EFE8;

		&-title {
			margin: 0;
			color: #434343;
			font-weight: 600;
			font-size: 20px;
			letter-spacing: 0.05em;
			@media (max-width: 414px) {
				font-size: 16px;
			}
		}
		&-addBtn {
			display: block;
			max-width: 20px;
			width: 100%;
			height: 20px;
			margin-left: auto;
			background-image: url('../svg/icons/icon-open.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	&__body {
		padding: 25px 38px;
		&-allBtn {
			padding: 12px 17px;
			font-size: 14px;
			font-weight: 600;
			text-align: center;
			color: #FF6230;
			border: 2px solid #E9EFE8;
			transition: background-color .1s ease-in, border .1s ease-in;
			&:hover {
				border: 2px solid #679100;
				background-color: #679100;
				color: white;
			}
		}
		&-list {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -32px;
			padding: 0;
			margin-top: 25px;
			list-style: none;
		}
		&-item {
			width: 100%;
			max-width: 290px;
			margin: 0 32px;
			margin-bottom: 20px;
		}

	}
	&__checkbox {
		display: flex;
		cursor: pointer;
		&-hidden {
			display: none;
			&:checked {
				& ~ .cost-calculate__checkbox-icon {
					&::after {
						background-color: #FF6230;
					}
				}
			}
		}
		&-icon {
			position: relative;
			display: block;
			width: 19px;
			height: 19px;
			margin-right: 13px;
			border: 1px solid #E9EFE8;
			&::after {
				content: " ";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 11px;
				height: 11px;
				transform: translate(-50%, -50%);
				transition: all linear .1s;
			}
		}
		&-title {
			display: block;
			width: 100%;
		}
	}
}

.border-grey {
	background-color: #E9EFE8;
	height: 2px;
	border: none;
	margin: 0;
}

.text-center {
	text-align: center;

}

.mt46 {
	margin-top: 46px;
}

.mb27 {
	margin-bottom: 27px;
}

.cost-result {
	display: flex;
	flex-direction: column;
	margin: 0;
	margin-top: 27px;
	padding: 0;
	list-style: none;
	&__row {
		display: flex;
		border: 2px solid #E9EFE8;
		justify-content: space-between;
		&:not(:last-child) {
			border-bottom: 0;
		}
		&-left {
			display: flex;
			align-items: center;
			flex: 1 1 70%;
			max-width: 1380px;
			padding: 19px 25px 19px 38px;
		}
		&-right {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1 1 30%;
			max-width: 60px;
			border-left: 2px solid #E9EFE8;
		}
		&-title {
			margin: 0;
			word-break: break-all;
			margin-right: auto;
			font-weight: 600;
			font-size: 14px;
			color: #434343;
			@media(min-width: 595px) {
				font-size: 20px;
			}
			@media (max-width: 595px) {
				max-width: 210px;
				padding-right: 13px;
			}
		}
		&-price {
			font-family: 'Akrobat';
			font-weight: bold;
			font-size: 16px;
			text-align: right;
			color: #263238;
			@media(min-width: 595px) {
				font-size: 30px;
			}
			@media(max-width: 595px) {
				align-self: baseline;
			}
		}
		&-del {
			display: block;
			align-self: center;
			width: 40px;
			height: 40px;
			background-image: url('../svg/icons/cost-del.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
}

.cost-price {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 54px;
	margin-bottom: 49px;
	&__container {
		width: 100%;
		max-width: 580px;
	}
}

.cost-total {
	display: flex;
	align-items: baseline;
	justify-content: flex-end;
	&__value {
		margin-right: auto;
		font-family: 'Akrobat';
		font-weight: bold;
		font-size: 28px;
		color: #263238;
		@media (min-width: 416px) {
			font-size: 36px;
		}
	}
	&__price {
		font-family: 'Akrobat';
		font-weight: bold;
		font-size: 40px;
		line-height: 111.5%;
		text-align: right;
		color: #FF6230;
		@media(min-width: 416px) {
			font-size: 62px;
		}
	}

}

.cost-call {
	display: flex;
	align-items: center;
	margin-top: 32px;
	@media (max-width: 595px) {
		flex-direction: column;
	}
	&__callback {
		display: block;
		padding: 10px 60px;
		margin-right: auto;
		font-weight: 600;
		font-size: 14px;
		text-align: center;
		color: #fff;
		background-color: #FF6230;
		text-decoration: none;
		transition: all ease .1s;
		&:hover {
			background-color: #679100;
		}
		@media(max-width: 595px) {
			width: 100%;
		}
	}
	&__download {
		position: relative;
		display: block;
		color: #F55E21;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 18px;
		text-decoration: none;
		width: 100%;
		max-width: 60%;
		text-align: right;
		transition: all ease .1s;
		&:hover {
			color: #679100;
		}
		&::before {
			content: '';
			position: absolute;
			width: 33px;
			height: 32px;
			background-image: url('../svg/icons/download-icon.svg');
			background-repeat: no-repeat;
			background-size: contain;
			left: 0;
		}
		&:hover {
			&::before {
				background-image: url('../svg/icons/download-icon-hover.svg');
			}
		}
		@media(max-width: 595px) {
			max-width: 345px;
			width: 100%;
			margin-top: 21px;
		}
		@media(max-width: 415px) {
			font-size: 14px;
			max-width: 280px;
		}
	}
}

.filter-panel__analyzes {
	position: relative;
	&-search {
		&:focus {
			outline: none;
			border: 2px solid #679100;
		}
		&.active {
			&:focus {
				border-bottom: none;
			}
		}
	}
	&-body {
		display: none;
		position: absolute;
		width: 100%;
		margin: 0px;
		padding: 0px;
		list-style: none;
		top: 45px;
		left: 50%;
		transform: translate(-50%);
		background-color: #fff;
		padding: 0 20px;
		&.active {
			display: block;
			border-left: 2px solid #679100;
			border-bottom: 2px solid #679100;
			border-right: 2px solid #679100;
		}
	}
	&-item {
		display: flex;
		justify-content: flex-start;
		margin-bottom: 10px;
		&:first-child {
			margin-top: 3px;
			padding-top: 3px;
			border-top: 2px solid #e9efe8;
		}
	}
	&-link {
		display: block;
		padding: 4px 0;
		text-decoration: none;
		color: #263238;
		width: 100%;
		text-align: inherit;
	}
}