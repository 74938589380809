.social-links {
  display:flex;

  &__item {
    display: block;

    width: 35px;
    height: 20px;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &_youtube {
      background-image: url("../svg/socialWebNew/youtube.svg");
    }

    &_vk {
      background-image: url("../svg/socialWebNew/vk.svg");
    }

    &_fb {
      background-image: url("../svg/socialWebNew/fb.svg");
    }

    &_vb {
      background-image: url("../svg/socialWebNew/vb.svg");
    }

    &_wa {
      background-image: url("../svg/socialWebNew/wa.svg");
    }

    &_ok {
      background-image: url("../svg/socialWebNew/ok.svg");
    }

    &_inst {
      background-image: url("../svg/socialWebNew/inst.svg");
    }
  }
}