.spoiler {
  margin-bottom: 2px;
  border: 2px solid $third-color;

  &_mb-2 {
    margin-bottom: -2px;
  }

  &.open {

    .spoiler__indicator::after {
      opacity: 0;
      transition: opacity .2s ease-in;
    }

    .spoiler__body {
      transition: height .2s ease-in;
    }
  }

  &__header {
    position: relative;

    cursor: pointer;
    padding: 10px;
    padding-right: 40px;
    background-color: $third-color;

    &:hover {
      background-color: #d4e0d2;
    }

    @media (min-width: 576px) {
      padding: 20px 40px;
    }
  }

  &__indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto 0;

    width: 20px;
    height: 20px;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      margin: auto;

      width: 100%;
      height: 2px;

      background-color: $fourth-color;
    }

    &::after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      margin: auto;

      width: 2px;
      height: 100%;

      background-color: $fourth-color;

      opacity: 1;
      transition: opacity .2s ease-in;
    }
  }

  &__section-name {
    margin: 0;

    font-size: 16px;
    font-weight: 600;

    @media (min-width: 576px) {
      font-size: 20px;
    }
  }

  &__body {
    overflow-y: hidden;
    transition: height .2s ease-in;
  }

  &__row {
    display: flex;
    align-items: center;

    padding: 10px;

    background-color: #fff;
    border-bottom: 2px solid $third-color;

    text-decoration: none;
    color: $font-color;

    &:last-child {
      border-bottom: none;
    }

    &:not(&_no-hover):hover {
      background-color: $first-color;
      color: white;

      .spoiler__price {
        color: white;
      }
    }

    @media (min-width: 576px) {
      padding: 20px 40px;
    }

    &_more {
      padding: 10px;
      background-color: $third-color;

      &:hover {
        background-color: $third-color;
      }

      @media (min-width: 576px) {
        padding: 15px 40px;
      }
    }

    &_full {
      align-items: flex-end;
      justify-content: flex-end;
      background-color: rgba(233, 239, 232, .5);

      b {
        font-size: 18px;
      }
    }
  }

  &__more {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $fourth-color;

    @media (min-width: 576px) {
      font-size: 14px;
    }
  }

  &__amenities-wrap {
    margin: 0 15px 0 0;

    @media (min-width: 576px) {
      margin-right: 30px;
    }
  }

  &__amenities-descr {
    margin: 0;

    font-size: 14px;
    line-height: 1.6;

    @media (min-width: 576px) {
      font-size: 18px;
    }
  }

  &__text {
    font-size: 14px;
    margin-right: 15px;

    b {
      font-size: 18px;
    }
  }

  &__amenities {
    margin: 0 15px 0 0;
    font-size: 14px;

    @media (min-width: 576px) {
      margin-right: 30px;
      font-size: 18px;
    }

    &_stock {
      display: flex;
      align-items: center;

      &::after {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        content: 'Акция %';

        margin-left: 15px;
        padding-right: 5px;

        width: 76px;
        height: 25px;

        background-color: $fourth-color;

        font-size: 12px;
        text-transform: uppercase;
        color: white;

        clip-path: polygon(6px 0%, 100% 0%, 100% 100%, 6px 100%, 0% 50%);
      }
    }

    &_bold {
      margin-bottom: 5px;
      font-weight: 600;
    }

    &_home {
      display: flex;
      align-items: center;

      &::after {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        content: '';

        margin-left: 15px;
        padding-right: 5px;

        width: 39px;
        height: 25px;

        background: $first-color url('../svg/icons/home.svg') center/35% no-repeat;

        clip-path: polygon(6px 0%, 100% 0%, 100% 100%, 6px 100%, 0% 50%);
      }
    }
  }

  &__term {
    flex: 0 0 auto;

    min-width: 70px;
    margin-left: auto;
    margin-right: 80px;

    font-weight: 600;
    font-size: 14px;
    text-align: right;

    @media (min-width: 576px) {
      min-width: 100px;
      font-size: 20px;
    }
    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  &__price {
    align-self: flex-start;
    flex: 0 0 auto;

    min-width: 70px;

    font-family: 'Akrobat', Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    text-align: right;
    color: $fourth-color;

    @media (min-width: 576px) {
      min-width: 100px;
    }
    @media (min-width: 768px) {
      font-size: 36px;
    }

    &_ml-auto {
      margin-left: auto;
    }

    &_green {
      margin-left: 70px;
      color: $first-color;

      @media (min-width: 768px) {
        font-size: 24px;
      }
    }
  }
}
