.card {
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 15px;
  background-color: $third-color;

  @media (min-width: 1024px) {
    width: 460px;
    padding: 25px;
  }
  @media (min-width: 1360px) {
    width: 337px;
    padding: 28px 20px 46px 20px;
  }

  &_main {
    position: relative;
    z-index: 1;

    margin-top: 15px;
    margin-bottom: 15px;

    background-color: white;
    border: 13px solid rgba(103, 145, 0, .2);

    @media (min-width: 1024px) {
      width: 485px;
      margin: -50px -13px -13px;
    }
  }

  &__title {
    position: relative;

    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 20px;

    font-family: 'Akrobat', Arial, sans-serif;
    font-size: 24px;
    color: $fourth-color;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 24px;
    }

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;

      width: 100%;
      height: 1px;

      background-color: $first-color;
    }
  }
	&__price {
		color: #FF6230;
		font-size: 36px;
		font-weight: bold;
		font-family: Akrobat;
		margin-top: 16px;
		text-align: right;
	}

  &__list {

    .list__item {

      @media (min-width: 1024px) {
        line-height: 1.5;
      }
			&-icon {
				background-image: url('../../svg/icons/perf.svg');
				background-repeat: no-repeat;
				background-position: left 8%;
				&::before {
					background-color: inherit;
				}
			}
    }
  }
	&__description {
		font-size: 14px;
		line-height: 120%;
		margin-top: 21px;
	}
	&__button {
		display: block;
		font-weight: 600;
		text-align: center;
		font-size: 14px;
		color: #FF6230;
		padding: 12px;
		width: 100%;
		height: auto;
		margin-top: 20px;
		transition: all .1s ease-in;
		&:hover {
			background-color: #679100;
			color: #fff;
			transition: all .1s ease-in;
		}
	}

  &__btns {
    margin-top: auto;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1360px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }

    .button {
      text-transform: none;
    }
  }
}


.cards-wrap {
	margin: 0 -15px;
	align-items: baseline;
	& .cards-list {
		@media(min-width: 1360px) {
			padding: 28px 30px 25px;
		}
		@media(min-width: 1025px) {
			width: 337px;
			margin: 0 15px;
		}
		& .card__title {
			min-height: 101px;
			font-size: 24px;
			&::before {
    		width: 100%;
    		height: 1px;
			}
		}
		& .list__item {
			background-image: url("../../svg/icons/perf.svg");
			background-repeat: no-repeat;
			background-position: left 7%;
			&::before {
			display: none;
			}
		}
		& .cards-list-price {
			font-family: Akrobat;
			font-weight: bold;
			font-size: 36px;
			text-align: right;
			letter-spacing: 0.05em;
			color: #FF6230;
			padding-top: 16px;
    	border-top: 1px solid #608D00;
		}
		& .button-cards {
			text-align: center;
			letter-spacing: 0.05em;
			color: #FF6230;
			height: auto;
    	padding: 14px;
    	margin-top: 20px;
			transition: all .1s ease-in;
			&:hover {
				background-color: #679100;
    		color: #fff;
    		transition: all .1s ease-in;
			}
		}
		& .cards-list-descr {
			margin-top: 21px;
			font-family: Inter;
			font-size: 14px;
			min-height: 80px;
		}
	}
}

.medical-home {
	margin-top: 53px;
	padding-bottom: 35px;
	&__title {
		font-family: Akrobat;
		font-weight: bold;
		font-size: 36px;
		text-align: center;
		text-transform: uppercase;
		color: #434343;
	}
	&__list {
		column-count: 2;
		font-size: 14px;
		@media(min-width: 550px) {
			column-count: 3;
			font-size: 18px;
		}
		padding: 0;
	}
	&__item {
		margin-bottom: 5px;
		padding-left: 25px;
		position: relative;
		line-height: 164%;
		&::marker {
			font-size: 0;

		}
		&::before {
				content: "";
				position: absolute;
				left: 0%;
				top: 50%;
				width: 10px;
				height: 10px;
				background: #E9EFE8;
				border-radius: 50%;
				transform: translate(-50%, -50%);
			}
	}
}


.frame__head.text-center {
	justify-content: center;
}

.content__btn-wrap.mb20 {
	margin-bottom: 30px;
}