.three-cards {
  position: relative;
  padding-top: 30px;

  @media (min-width: 768px) {
    padding-top: 45px;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;

    width: 100%;
    height: 380px;
  }

  &::before {
    z-index: -2;
    background: url('../images/patterns/green.png') center/40%;
  }

  &::after {
    z-index: -1;
    background: radial-gradient(62.56% 114.43% at 35.74% 34.9%, #A4BA04 0%, black 100%);
    opacity: .3;
  }

  &_mb {
    margin-bottom: 33px;
  }

  &__title {
    margin: 0 auto 30px auto;

    color: white;
    text-align: center;

    @media (min-width: 1024px) {
      margin: 0 auto 85px auto;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;

    @media (min-width: 1360px) {
      flex-direction: row;
    }

    &_analyze {
      align-items:center;

      @media (min-width: 1360px) {
        align-items: flex-start;
        justify-content: center;
      }

      & > * {
        margin-bottom: 15px;

        @media (min-width: 1360px) {
          margin-bottom: 0;
          margin-right: 30px;
        }

        &:last-child {
          margin-bottom: 0;

          @media (min-width: 1360px) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
