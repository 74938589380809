.stock {

  &__hr {
    margin-bottom: 40px;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;

    margin-bottom: 30px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;

      margin-bottom: 60px;
    }
  }
}