.directions {
  padding: 15px;
  border: 2px solid $third-color;

  @media (min-width: 576px) {
    margin-left: -60px;
    padding: 60px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__block {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    font-size: 20px;
  }

  &__list {
    @include clearList();
    margin-left: 20px;
  }

  &__item {
    position: relative;

    margin-bottom: 15px;
    padding-left: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 0;

      width: 10px;
      height: 10px;

      background-color: $third-color;
      border-radius: 100%;
    }
  }

  &__link {
    font-size: 16px;
    color: $font-color;
    text-decoration: none;

    @media (min-width: 576px) {
      font-size: 18px;
    }
  }
}