.swiper-main {
  display: none;

  @media (min-width: 768px) {
    position: relative;
    width: 100%;
  }
  @media (min-width: 1200px) {
    display: block;
    margin-bottom: 20px;
  }
  @media (min-width: 768px) and (max-height: 768px) {
    display: block;
    margin-bottom: 80px;
  }

  &__slide {

    @media (min-width: 768px) {
      position: relative;
      display: flex;
      justify-content: space-between;

      width: 100%;

      background-color: $third-color;
    }
    @media (min-width: 1200px) {
      max-width: 1440px;
      height: 460px;
    }
    @media (min-width: 768px) and (max-height: 768px) {
      max-width: 1150px;
      height: 420px;
    }
  }

  &__content {

    @media (min-width: 1200px) {
      position: relative;

      height: 100%;

      padding: 80px 80px 130px 90px;

      display: flex;
      flex-direction: column;
    }
  }

  &__wrap {

    @media (min-width: 1200px) {
      width: 100%;
      height: 100%;
      overflow-y: hidden;
    }
  }

  &__title {
		& a {
			color: #434343;
			text-decoration: none;
			transition: color .1s;
			&:hover {
				color: #a4ba04;
				transition: color .1s;
			}
		}
    @media (min-width: 1200px) {
      margin-top: 0;
      margin-bottom: 30px;

      font-family: 'Akrobat', Arial, sans-serif;
      font-size: 48px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__description {

    @media (min-width: 1200px) {
      margin-top: 0;
      margin-bottom: auto;

      font-size: 18px;
      font-weight: normal;
    }
  }

  &__btn {

    @media (min-width: 1200px) {
      position: absolute;
      left: 90px;
      bottom: 2px;

      width: 215px;
      height: 65px;

      padding: 20px 50px;

      border: none;
      background-color: #fff;

      font-size: 18px;
      text-transform: uppercase;
      color: $fourth-color;

      &:hover {
        border: none;
        background-color: $first-color;
        color: white;
      }
    }
  }

  &__img {

    @media (min-width: 768px) {
      flex: 0 0 auto;
      height: 100%;
      background: url('../images/other/pattern.png') repeat;
    }
    @media (min-width: 1200px) {
      width: 720px;
    }
    @media (min-width: 768px) and (max-height: 768px) {
      width: 660px;
    }

    img {

      @media (min-width: 1200px) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__tools {

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;

      width: 100%;
    }
    @media (min-width: 1200px) {
      max-width: 1440px;
      height: 460px;
    }
    @media (min-width: 768px) and (max-height: 768px) {
      max-width: 1150px;
      height: 420px;
    }
  }

  &__prev, &__next {

    @media (min-width: 1200px) {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      z-index: 1;

      &:hover {
        opacity: 0.6;
      }

      &::after {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }
  }

  &__prev {

    @media (min-width: 1200px) {
      left: 30px;

      &::after {
        left: 0;
        @include arrow(30px, 2px, 'left', $font-color);
      }
    }
  }

  &__next {

    @media (min-width: 1200px) {
      right: 30px;

      &::after {
        right: 0;
        @include arrow(30px, 2px, 'right', $font-color);
      }
    }
  }

  &__pagination {

    @media (min-width: 768px) {
      position: absolute;
      bottom: 60px;

      display: flex;

      padding-left: 10px;
      padding-right: 10px;

      background-color: rgba(255, 255, 255, 0.3);
    }
    @media (min-width: 1200px) {
      right: 110px;
    }
    @media (min-width: 768px) and (max-height: 768px) {
      right: 150px;
    }
  }

  .swiper-pagination-bullet {

    @media (min-width: 1200px) {
      width: 56px;
      height: 30px;

      margin: 0 6px;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 0;
      background-color: transparent;
      opacity: 1;

      &::before {
        content: '';

        width: 56px;
        height: 2px;

        background-color: #fff;
      }
    }
  }

  .swiper-pagination-bullet-active {

    @media (min-width: 1200px) {
      &::before {
        background-color: $fourth-color;
      }
    }
  }
}