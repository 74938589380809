.docs {
  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  width: 100%;
  min-height: 100px;

  margin-top: 20px;
  margin-bottom: 40px;

  &:first-child {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  &__pic {
    width: 85px;
    height: 100px;
    margin-right: 30px;

    flex: 0 0 auto;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__text {
    font-size: 18px;
  }

  &__link {
    display: flex;
    align-items:center;

    text-decoration: none;
    color: $font-color;
  }
}