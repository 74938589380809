.search-page {

  &__hr {
    margin-bottom: 20px;
  }

  &__filter-panel {
    margin-bottom: 20px;
  }

  &__result-text {
    margin-top: 0;
    margin-bottom: 20px;
  }
}