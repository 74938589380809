.list {
  display: flex;
  flex-direction: column;

  &_red {

    .list__item {

      &::before {
        background-color: $fourth-color;
      }
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 25px;

    &_center {
      margin: 0 auto 25px auto;
    }
		&--align-center {
			text-align: center;
			text-transform: inherit;
		}
    &_green {
      color: $first-color;
    }

    &_orange {
      position: relative;

      margin-bottom: 50px;

      color: $fourth-color;

      &::before {
        position: absolute;
        content: '';
        bottom: -20px;
        transform: translateX(-50%);
        margin-left: 50%;

        width: 225px;
        height: 6px;

        background: url('../svg/icons/king.svg') center/contain no-repeat;

        @media (min-width: 768px) {
          width: 450px;
          height: 13px;
        }
      }
    }
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: 20px;

    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
  }

  &__list {
    @include clearList();

    &_mb {
      margin-bottom: 40px;
    }
  }

  &__item {
    position: relative;

    margin-bottom: 15px;
    padding-left: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      position: absolute;
      content: '';
      top: 5px;
      left: 0;

      width: 10px;
      height: 10px;

      border-radius: 100%;
      background-color: $third-color;
    }

    &_check {
      font-weight: 600;

      &::before {
        width: 25px;
        height: 15px;

        background: url('../svg/icons/two-check.svg') center/contain no-repeat;
      }
    }
  }

  &__descr {
    font-size: 18px;
  }
	&__text_normal {
		text-transform: inherit;
    text-align: center;
	}
}
