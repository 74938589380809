.card-analyze {
  position: relative;

  width: 100%;
  max-width: 460px;

  display: flex;
  flex-direction: column;

  padding: 15px;
  background-color: $third-color;

  font-size: 18px;
  line-height: 1.5;
  color: $font-color;

  @media (min-width: 1024px) {
    width: 460px;
    padding: 25px;
  }
  @media (min-width: 1360px) {
    width: 460px;
    padding: 36px 40px 25px;
  }

  &__head {
    margin-bottom: 15px;
  }

  &__title {
    max-width: 200px;
    height: 60px;
    margin-top: 45px;
    overflow: hidden;

    @media (min-width: 1360px) {
      height: 100px;
    }
  }

  &__img {
    position: absolute;
    top: 20px;
    right: 20px;

    width: 80px;
    height: 80px;

    @media (min-width: 1900px) {
      top: 40px;
      right: 55px;
    }
  }

  &__text {
    margin: 0;

    &_max-height {
      height: 81px;

      display: grid;
      grid-template-columns: 1fr;
      overflow: hidden;
    }
  }

  &__list {
    @include clearList();
    padding-bottom: 60px;
  }

  &__item {
    display: flex;
    flex-direction: column;

    margin-bottom: 10px;

    @media (min-width: 768px) {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__descr {
    font-weight: 600;
  }

  &__footer {
    display: flex;

    margin-top: auto;
    padding-top: 15px;

    border-top: 1px solid $first-color;
  }

  &__price {
    margin-left: auto;

    font-family: 'Akrobat', Arial, sans-serif;
    font-size: 36px;
    color: $fourth-color;

    @media (min-width: 768px) {
      font-size: 62px;
    }
  }

  &__spoiler-head {
    cursor: pointer;
    position: relative;

    padding-top: 15px;
    padding-bottom: 15px;

    color: $fourth-color;
    font-size: 14px;

    transition: all .2s ease-in;
  }

  &__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;

    width: 14px;
    height: 14px;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto 0;

      width: 100%;
      height: 2px;

      background-color: $fourth-color;
    }

    &::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      left: 0;
      margin: 0 auto;

      width: 2px;
      height: 100%;

      background-color: $fourth-color;
    }
  }
}

.spoiler.open {

  .card-analyze__spoiler-head {
    margin-bottom: 15px;

    border-bottom: 1px solid $first-color;
    transition: all .2s ease-in;
  }

  .card-analyze__icon {

    &::after {
      content: none;
    }
  }
}
