.profile {
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 70px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;

    margin-bottom: 50px;

    @media (min-width: 768px) {
      flex-direction: initial;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    flex: 1 1 auto;
    margin-right: 30px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 35px;
  }

  &__profession {
    margin-top: 0;
    margin-bottom: 15px;
  }

  &__experience {
    margin-top: 0;
    margin-bottom: 15px;
  }

  &__text {
    margin-top: 0;
    margin-bottom: 30px;

    @media (min-width: 576px) {
      margin-bottom: 50px;
    }
  }

  &__btn {
    margin-top: auto;
    max-width: 300px;
  }

  &__pic {
    order: -1;
    width: 100%;
    height: 175px;

    margin-bottom: 15px;

    flex: 0 0 auto;
    background: url('../images/other/pattern.png');

    @media (min-width: 576px) {
      width: 450px;
      height: 350px;
    }
    @media (min-width: 768px) {
      order: initial;
      margin-bottom: 0;
    }

    img {
      width: 300px;
      height: 100%;
      object-fit: contain;
      object-position: center;

      @media (min-width: 576px) {
        width: 100%;
      }
    }
  }
}