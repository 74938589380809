.link-price {
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 15px;

  border: 2px solid $fourth-color;

  @media (min-width: 1024px) {
    height: 60px;
    margin-bottom: 30px;
  }

  a {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    color: $fourth-color;
    text-transform: uppercase;
    text-decoration: none;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    &::before {
      content: '';

      width: 32px;
      height: 21px;

      margin-right: 10px;

      background: url('../svg/icons/cloud.svg') center/contain no-repeat;
    }
  }
}
